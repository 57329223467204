import styled from 'styled-components';

const Wrap = styled.div`
  width: 100%;

  line-height: 1.4em;
  border-radius: 10px;
  border: 4px solid #d7f1ff;
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  flex-direction: column;
  height: auto;
  padding: 20px 20px 40px;
  margin-bottom: 16px;
`;

const WrapSmall = styled.div`
  width: 100%;
  line-height: 1.4em;
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  flex-direction: column;
  height: auto;
  padding: 20px;
  margin-bottom: 16px;
`;

export default { Wrap, WrapSmall };
