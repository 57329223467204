import { AppState } from '../../interfaces';
import { connect } from 'react-redux';
import ExternalLinks from './ExternalLinks';
import { getExternalLinks } from '../../actions/rewardProgram';

const mapStateToProps = (state: AppState) => ({
  appWidth: state.uiReducer.appWidth,
  externalLinks: state.externalLinksReducer,
});

const mapDispatchToProps = dispatch => ({
  getExternalLinks: data => dispatch(getExternalLinks(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExternalLinks);
