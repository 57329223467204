import { AppReducer } from '../interfaces';
import { normalize, schema } from 'normalizr';
import * as _ from 'lodash';
import { createReducer } from '@reduxjs/toolkit';
import i18next from 'i18next';

export const initialState: AppReducer = {
  lockApp: true,
  config: {
    status: {
      loading: false,
      error: null,
      done: false,
    },
    data: null,
    dataNormalized: null,
  },
  generalData: {
    status: {
      loadingGeneral: false,
      errorGeneral: null,
      doneGeneral: false,
    },
    data: null,
  },
  clientConfig: {
    status: {
      loading: false,
      error: null,
      done: false,
    },
    data: null,
  },
  locationConfig: {
    status: {
      loading: false,
      error: null,
      done: false,
    },
    data: null,
  },
  userData: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
};

const appReducer = createReducer(initialState, {
  UNLOCK_APP: state => {
    state.lockApp = false;
  },
  REFRESH_ACCESS_TOKEN_FULFILLED: state => {
    state.lockApp = false;
  },

  REFRESH_ACCESS_TOKEN_REJECTED: state => {
    state.lockApp = false;
  },

  GET_PREFERENCES_PENDING: state => {
    state.userData.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  GET_PREFERENCES_FULFILLED: (state, action) => {
    state.userData.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.userData.data = _.get(action, 'payload.data.data.items', i18next.t('portal.general.error_message'));
  },

  GET_PREFERENCES_REJECTED: (state, action) => {
    state.userData.status = {
      loading: false,
      error: _.get(action, 'payload.data.errors', i18next.t('portal.general.error_message')),
      done: false,
    };
  },

  GET_LOCATION_CONFIG_PENDING: (state, action) => {
    state.clientConfig.status = {
      loading: true,
      error: null,
      done: false,
    };

    state.locationConfig.data = null;
  },

  GET_LOCATION_CONFIG_FULFILLED: (state, action) => {
    state.clientConfig.status = {
      loading: false,
      error: null,
      done: true,
    };
    state.clientConfig.data = action.payload.data;
  },
  GET_LOCATION_CONFIG_REJECTED: (state, action) => {
    state.clientConfig.status = {
      loading: false,
      error: _.get(action, 'payload.data.errors[0]', i18next.t('portal.general.error_message')),
      done: true,
    };
  },
  GET_CONFIG_PENDING: state => {
    state.config.status = {
      loading: true,
      error: null,
      done: false,
    };
    state.config.data = null;
  },

  GET_CONFIG_FULFILLED: (state, action) => {
    const pagesEntity = new schema.Entity('pages', undefined, {
      idAttribute: 'pathId',
    });
    const appSchema = new schema.Array(pagesEntity);
    const normalizedData: any = normalize(_.get(action, 'payload.data.navElements', {}), appSchema);

    state.config.status = {
      loading: false,
      error: null,
      done: true,
    };
    state.config.data = action.payload.data;
    state.config.dataNormalized = normalizedData;
  },

  GET_CONFIG_REJECTED: state => {
    state.config.status = {
      loading: false,
      error: true,
      done: true,
    };
    state.config.data = null;
  },

  GET_GENERAL_DATA_PENDING: state => {
    state.generalData.status = {
      loadingGeneral: true,
      errorGeneral: null,
      doneGeneral: false,
    };
  },

  GET_GENERAL_DATA_FULFILLED: (state, action) => {
    state.generalData.status = {
      loadingGeneral: false,
      errorGeneral: null,
      doneGeneral: true,
    };
    state.generalData.data = action.payload.data;
  },

  GET_GENERAL_DATA_REJECTED: state => {
    state.generalData.status = {
      loadingGeneral: false,
      errorGeneral: true,
      doneGeneral: true,
    };
    state.generalData.data = null;
  },

  DONATE_POINTS_FULFILLED: state => {
    if (state.generalData.data && state.generalData.data.coins) {
      state.generalData.data.coins = 0;
    }
  },
  GET_LOCATION_PENDING: state => {
    state.locationConfig.status = {
      loading: true,
      error: null,
      done: false,
    };
  },
  GET_LOCATION_FULFILLED: (state, action) => {
    state.locationConfig.status = {
      loading: false,
      error: null,
      done: true,
    };
    state.locationConfig.data = action.payload.data;
  },
  GET_LOCATION_REJECTED: (state, action) => {
    state.locationConfig.status = {
      loading: false,
      error: _.get(action, 'payload.data.errors[0]', i18next.t('portal.general.error_message')),
      done: true,
    };
  },
});

export default appReducer;
