import React from 'react';
import { Link } from 'react-router-dom';

interface LinkHandlerProps {
  path?: string;
  label?: string;
  external?: boolean;
  internal?: boolean;
}
const LinkHandler: React.FC<LinkHandlerProps> = props => {
  const { path, children, label, external, internal } = props;

  if (!path) {
    return null;
  }

  const renderLink = () => {
    if (path.substr(0, 4) === 'http') {
      return (
        <a href={path} aria-label={label} rel="noopener noreferrer" target={internal ? '_self' : '_blank'}>
          {children}
        </a>
      );
    }
    return (
      <Link target={external ? '_blank' : '_self'} to={path}>
        {children}
      </Link>
    );
  };

  return renderLink();
};
export default LinkHandler;
