import styled, { css } from 'styled-components';
import { QAcolors } from '../../../assets/variables';
import Shared from '../../../assets/SharedStyles.style';

const Main = styled.div`
  font-family: 'Open Sans', sans-serif;
  ${props => {
    if (props.swsmInternal) {
      return css`
        min-height: 100vh;
      `;
    }
  }}
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: margin 200ms cubic-bezier(0.45, 0.22, 0.41, 0.92);
  margin-left: ${props => (props.shrink ? '500px' : '0')};
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    height: 100vh;
  }
`;

const ProgressBar = styled.div`
  height: 16px;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(to right, #00597c 0%, #63cbe8 100%);
  z-index: 999;
  box-shadow: 0 1px 3px rgba(${QAcolors.baseDark}, 0.3);
`;

const Restart = styled.div`
  ${props => {
    if (props.isDesktop) {
      return css`
        margin-top: 100px;
      `;
    }
  }}
  text-align: center;
`;

const Row = styled.div`
  ${Shared.clearfix}
  max-width: 900px;
  margin: auto;
  margin-bottom: 6px;
  padding: 0 10px;
`;

const InputWrapper = styled.div`
  position: relative;
  display: block;
  button {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0;
  }
`;

const Input = styled.input`
  display: flex;
  font-size: 1.25rem;
  color: #4a4a4a;
  padding: 1.1em;
  border: 0 solid transparent;
  min-height: auto;
  background-color: #fff;
  font-weight: 400;
  border: 1px solid ${QAcolors.primary};
  line-height: inherit;
  border-radius: 10px;
`;

const Modal = styled.div`
  transition: all 0.2s ease-in-out;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  overflow: auto;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 440px;
  // margin: auto;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 300px;
`;

const ModalTitle = styled.div`
  display: flex;
  font-weight: bolder;
  margin-bottom: 20px;
`;

const ModalText = styled.div`
  display: flex;
  max-width: 440px;
  margin: auto;
  text-align: center;
  margin-top: 20px;
  padding: 0 10px;
`;

export default {
  Main,
  ProgressBar,
  Input,
  InputWrapper,
  ModalText,
  Restart,
  Row,
  Modal,
  ModalContent,
  ModalTitle,
};
