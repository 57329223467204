import axios from 'axios';
import history from '../history';
import { getMicroServiceAuthUrl, postAppMessage, getUtmTags, getVariable } from '../helpers';
import * as Sentry from '@sentry/browser';
import store from 'store2';

export function logIn({ email, password }) {
  const utm_tags = getUtmTags();

  return dispatch => {
    dispatch(logInPending());
    return axios
      .post(`${getMicroServiceAuthUrl('user')}login`, {
        email,
        password,
        utm_tags,
      })
      .then(resp => {
        dispatch(logInFulfilled(resp));
        const swsmInternal = getVariable('mode', 'swsm', false);
        if (swsmInternal) {
          console.log('here login');
          window._paq.push(['setCustomDimension', 1, resp.data.waterClient]);
        }

        postAppMessage({ loggedIn: `${resp.data.accessToken}` });
        Sentry.configureScope(scope => {
          //TODO: might not working
          scope.setUser({ id: resp.data.id });
        });
      })
      .then(() => {
        history.push('/portal');
        //Action banner
        store('swsmUserAction', true);
      })
      .catch(error => {
        dispatch(logInRejected(error));
      });
  };
}

export const resetPasswordRequest = email => {
  return {
    type: 'RESET_PASSWORD',
    payload: axios.post(`${getMicroServiceAuthUrl('user')}reset`, { email }),
  };
};

export const resetPasswordRequestRepeat = () => {
  return {
    type: 'RESET_PASSWORD_REPEAT',
  };
};

export const resetPasswordUpdate = (password, token) => {
  return dispatch => {
    const response = dispatch({
      type: 'RESET_PASSWORD_UPDATE',
      payload: axios.post(`${getMicroServiceAuthUrl('user')}reset/password`, { password, token }),
    });

    response
      .then(res => {
        history.push('/login');
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };
};

export const unSubscribe = token => {
  return {
    type: 'UNSUBSCRIBE',
    payload: axios.post(`${getMicroServiceAuthUrl('user')}unsubscribe`, { token }),
  };
};

export const logInPending = () => {
  return {
    type: 'LOG_IN_PENDING',
  };
};
export const logInFulfilled = resp => {
  return {
    type: 'LOG_IN_FULFILLED',
    payload: resp,
  };
};
export const logInRejected = resp => {
  return {
    type: 'LOG_IN_REJECTED',
    payload: resp,
  };
};

export const logOut = () => {
  history.push('/login');
  return dispatch => {
    return axios
      .post(`${getMicroServiceAuthUrl('user')}logout`)
      .then(resp => {
        store('swsmUser', false);
        localStorage.removeItem('swsmUserAction');

        dispatch(clearUserdata(resp));
        window.location.reload();
      })
      .catch(error => {
        store('swsmUser', false);
        dispatch(clearUserdata(error));
      });
  };
};
export const clearUserdata = resp => {
  return {
    type: 'CLEAR_USERDATA',
    payload: resp,
  };
};

export const refreshAccessToken = () => {
  return {
    type: 'REFRESH_ACCESS_TOKEN',
    payload: axios.post(`${getMicroServiceAuthUrl('user')}token`),
  };
};

export const unlockApp = () => {
  return {
    type: 'UNLOCK_APP',
  };
};
