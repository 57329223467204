import styled from 'styled-components';

export const IconWrap = styled.div`
  position: absolute;
  right: 15px;
  top: 33px;
`;

export const Error = styled.span`
  color: red;
`;
