import React from 'react';
import Styled from './FormInput.style';

interface FormInputProps {
  [any: string]: any;
}

const FormInput: React.FC<FormInputProps> = props => {
  return <Styled.Input {...props} />;
};

export default FormInput;
