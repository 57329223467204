import React from 'react';
import Loadable from 'react-loadable';

const SicCodes = Loadable({
  loader: () => import('./SicCodesControl'),
  loading() {
    return <div>Loading...</div>;
  },
});

export default SicCodes;
