import { ModalState, ModalShow, ModalActionTypes } from '../interfaces';

const initialState: ModalState = {
  modalType: undefined,
  modalProps: {},
};

const modal = (state = initialState, action: ModalShow) => {
  switch (action.type) {
    case ModalActionTypes.MODAL_SHOW:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
      };
    case ModalActionTypes.MODAL_HIDE:
      return initialState;
    default:
      return state;
  }
};

export default modal;
