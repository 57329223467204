import styled from 'styled-components';
import { colors } from '../../assets/variables';

export const Wrapper = styled.div`
  a {
    text-decoration: none;
    color: ${colors.primary};
    display: block;
    font-weight: 600;
    margin-bottom: 8px;
    &:hover {
      color: ${colors.action};
    }
  }
`;

export const ExternalLink = styled.p`
  a {
    text-decoration: none;
    color: ${colors.action};
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;
