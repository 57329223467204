import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import * as _ from 'lodash';
import store from 'store2';

const isProduction = !process.env.NODE_ENV || process.env.NODE_ENV === 'production';

const filterErrors = event => {
  if (!_.isArray(event.breadcrumbs)) {
    return event;
  }

  const error = _.chain(event.breadcrumbs)
    .takeRight(3)
    .value();

  let statusCode;

  _.forEach(error, err => {
    if (err.category === 'xhr') {
      statusCode = err.data.status_code;
    }
    return null;
  });

  const ignoreStatusCode = statusCode => {
    switch (statusCode) {
      case 400:
        return true;
      case 401:
        return true;
      case 403:
        return true;
      default:
        return false;
    }
  };

  if (ignoreStatusCode(statusCode)) {
    console.log('Sentry ignore');
    return null;
  }
  const swsmUser = store('swsmUser') || {};
  const uid = _.get(swsmUser, 'data.id', 'Unknown UID');
  event.user = { id: String(uid) };
  return event;
};

if (isProduction) {
  Sentry.init({
    dsn: 'https://01d92bf5590c470f939eac0ff9df6d7e@sentry.io/3459249',
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'ResizeObserver loop limit exceeded',
    ],
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.REACT_APP_SENTRY_ENV,
    beforeSend(event) {
      return filterErrors(event);
    },
  });
}

class SentryBoundary extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
    store.clear();
    // this.props.logOut();
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <div>
          <button onClick={() => Sentry.showReportDialog()}>Report feedback</button>
        </div>
      );
    }
    //when there's not an error, render children untouched
    return this.props.children;
  }
}

export default SentryBoundary;
