import React from 'react';
import classNames from 'classnames';
import { getControlKeys, getControlTypes, isConditionTrue } from '../../helpers';
import { colors } from '../../assets/variables';
import Input from '../FromInput';
import Styled from './DebugBar.style';
import * as _ from 'lodash';
import { Icon } from '@savewatersavemoney/swsm-ui';
import i18next from 'i18next';

interface DebugBarProps {
  savingengineReducer: any;
  goTo: Function;
  setShrink: Function;
}

class QADebugBar extends React.Component<DebugBarProps, any> {
  private refsCollection: any = React.createRef();

  constructor(props) {
    super(props);
    this.refsCollection = {};
    this.state = {
      open: false,
      search: '',
      searchResult: false,
    };
  }

  componentDidUpdate() {
    const { currentStep } = this.props.savingengineReducer;
    const { refsCollection } = this;

    if (refsCollection[currentStep]) {
      refsCollection[currentStep].scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  }

  updateSearch = event => {
    const { steps } = this.props.savingengineReducer;

    const searchString = event.target.value.toLowerCase();
    this.setState({ search: searchString });

    if (event.target.value.length > 0) {
      const filtered = Object.keys(steps).filter(key => {
        const step = steps[key];
        const controlKeys = getControlKeys(step);

        const inTitle = step.title && step.title.toLowerCase().indexOf(searchString) !== -1;
        const inText = step.text && step.text.toLowerCase().indexOf(searchString) !== -1;
        const inKey =
          controlKeys &&
          controlKeys
            .join(' ')
            .toLowerCase()
            .indexOf(searchString) !== -1;
        const inID = step.id && step.id.toString().indexOf(searchString) !== -1;

        return inText || inTitle || inID || inKey;
      });

      if (filtered.length > 0) {
        this.setState({ searchResult: filtered });
      }
      else {
        this.setState({ searchResult: [] });
      }
    }
    else {
      this.setState({ searchResult: false });
    }
  };

  stepRow = (step, index) => {
    const { answers, currentStep } = this.props.savingengineReducer;
    const { goTo } = this.props;

    const controlKeys = getControlKeys(step);
    const controlTypes = getControlTypes(step);
    let hasModal = false;

    if (controlTypes.length > 0 && controlTypes.includes('buttons')) {
      // we grab first element because button should exist only without any other control types
      step.controls[0].options.forEach(option => {
        if (option.value === 'modal') {
          // console.log('MODAL', step.id);
          hasModal = true;
        }
      });
    }

    const renderAnswers = () => {
      return (
        <ul className="answerKeys">
          {controlKeys &&
            _.map(controlKeys, key => {
              let keyDisplay;
              switch (typeof answers[key]) {
                case 'string':
                  keyDisplay = <span className="answer">{answers[key]}</span>;
                  break;
                case 'number':
                  keyDisplay = <span className="answer">{answers[key]}</span>;
                  break;
                case 'boolean':
                  keyDisplay = <span className="empty">UNCHECKED</span>;
                  if (answers[key]) {
                    keyDisplay = <span className="answer">CHECKED</span>;
                  }
                  break;
                default:
                  keyDisplay = <span className="empty">EMPTY</span>;
              }

              return (
                <li key={key}>
                  <span className="key">{key}</span>
                  {keyDisplay}
                </li>
              );
            })}
        </ul>
      );
    };

    const conditionLogicDisplay = conditional_logic => {
      if (conditional_logic) {
        const statusGreen = (bool = false) => {
          return {
            backgroundColor: bool ? 'green' : 'red',
            width: '10px',
            height: '10px',
            display: 'inline-block',
            borderRadius: '10px',
            marginRight: '3px',
            marginTop: '5px',
          };
        };

        return (
          <div className="debugRow__logic">
            <div>Show if:</div>
            {_.map(conditional_logic, (or, index) => (
              <span key={index} className="debugRow__logic__or">
                {_.map(or, (and, index) => (
                  <span className="debugRow__logic__and" key={index}>
                    {isConditionTrue(answers, and.id, and.operator, and.value) ? (
                      <span style={statusGreen(true)} />
                    ) : (
                      <span style={statusGreen()} />
                    )}
                    {and.id} {and.operator} {and.value}
                  </span>
                ))}
              </span>
            ))}
          </div>
        );
      }
    };

    return (
      <div
        ref={instance => {
          this.refsCollection[index] = instance;
        }}
        key={index}
        className={classNames('debugRow', {
          active: currentStep === index,
          visible: !this.state.searchResult || this.state.searchResult.includes(index.toString()),
        })}
        onClick={() => {
          goTo(index);
        }}
        style={{ borderLeft: `5px solid ${colors[step.color_theme]}` }}
      >
        <div className="debugRow__index">{step.id}</div>
        {hasModal && <div className="debugRow__modal">modal</div>}
        <div className="debugRow__icon">{step.icon && <Icon icon={step.icon} size={50} />}</div>
        <div className="debugRow__text">
          {i18next.t(step.text)}
          {step.type === 'chapter' && <div> {i18next.t(step.title)}</div>}
        </div>
        {conditionLogicDisplay(step.conditional_logic)}
        <div className="debugRow__keys">{renderAnswers()}</div>
      </div>
    );
  };

  toggleBar = () => {
    const { setShrink } = this.props;
    this.setState({ open: !this.state.open });
    setShrink(!this.state.open);
  };

  render() {
    const { fetched, steps } = this.props.savingengineReducer;

    if (!fetched || steps.length < 1) {
      return null;
    }

    return (
      <Styled.DebugBar className={classNames({ open: this.state.open })}>
        <div>
          <div id="debug-search">
            <Input
              onChange={this.updateSearch}
              value={this.state.search}
              className="form-control"
              type="text"
              placeholder="Search.."
              style={{ maxWidth: '446px' }}
              aria-label="Search"
            />
          </div>
        </div>
        <div className="debug-ins" tabIndex={0}>
          {_.map(steps, (step, index) => {
            return this.stepRow(step, index);
          })}
        </div>
        <button aria-label="Debug bar" className="debug-toggle" onClick={this.toggleBar}>
          Debug
        </button>
      </Styled.DebugBar>
    );
  }
}

export default QADebugBar;
