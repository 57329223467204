import React from 'react';
import Styled from './Card.style';
import { ChartBars, ChartBarNegative } from '../Charts';
import { ChartData } from '../../interfaces';
import Typography from '../Typography';
import { useTranslation } from 'react-i18next';

interface CardProps {
  id: number;
  title: string;
  rows: Array<any>;
  type: string;
  theme?: string | undefined;
  chartData: ChartData;
  desc: string;
  style?: React.CSSProperties;
  isDesktop?: boolean;
  displayMoney?: string;
}

const Card: React.FC<CardProps> = props => {
  const { title, theme, id, type, chartData, isDesktop, displayMoney } = props;
  const [t] = useTranslation('common');

  const renderRows = () => {
    switch (type) {
      case 'chart-bar':
        return (
          <ChartBars
            id={id}
            title={t(title)}
            key={id}
            theme={theme}
            displayMoney={displayMoney}
            chartData={chartData}
          />
        );
      case 'chart-bar-negative':
        return (
          <ChartBarNegative
            id={id}
            title={t(`${title}`)}
            key={id}
            displayMoney={displayMoney}
            theme={theme}
            chartData={chartData}
          />
        );
      default:
        return <div key={id}>{type} type don't exist</div>;
    }
  };
  const text_translation_title = t(title);

  return (
    <Styled.Card className="card" isDesktop={isDesktop} data-test="Card" id={`card-${id}`}>
      <Typography
        size="4"
        weight={600}
        style={{ textAlign: 'center', fontSize: '20px' }}
        text={text_translation_title}
        data-test="title"
      />
      {renderRows()}
    </Styled.Card>
  );
};

export default Card;
