import { PageFreeProductsReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import { normalize, schema } from 'normalizr';

const initialState: PageFreeProductsReducer = {
  basicInfo: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  products: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    groups: [],
    data: null,
  },
  basket: {},
  details: {
    email: '',
    title: '',
    firstName: '',
    lastName: '',
    phone: '',
    address: '',
    toc: false,
    marketing: false,
  },
  addresses: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: [],
  },
  preCheckout: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    basketRejected: false,
    creditAudit: {},
  },
  order: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
};

const pageTipsReducer = createReducer(initialState, {
  RESET: state => {
    state.basket = {};
  },

  ADD_TO_ORDER: (state, action) => {
    state.basket[action.id] = {
      total: _.get(state, `basket.${action.id}.total`, 0) + 1,
    };
  },

  PERSIST_DETAILS: (state, action) => {
    state.details = action.details;
  },

  REMOVE_FROM_ORDER: (state, action) => {
    const currentQuantity = _.get(state, `basket.${action.id}.total`);

    if (currentQuantity > 1) {
      state.basket[action.id].total = currentQuantity - 1;
    }
    else {
      delete state.basket[action.id];
    }
  },

  'GET_PAGE_FREE-PRODUCTS_PENDING': state => {
    state.basicInfo.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  'GET_PAGE_FREE-PRODUCTS_FULFILLED': (state, action) => {
    state.basicInfo.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.basket = {};
    state.basicInfo.data = action.payload.data.data;
  },

  'GET_PAGE_FREE-PRODUCTS_REJECTED': (state, action) => {
    state.basicInfo.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.basicInfo.data = action.payload.data;
  },

  GET_FREE_PRODUCTS_PENDING: state => {
    state.products.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  CLEAR_ADDRESS: state => {
    state.preCheckout.basketRejected = false;
    state.preCheckout.creditAudit = {};
    state.details.address = '';
    state.basket = {};
  },

  SUBMIT_ORDER_PENDING: state => {
    state.order.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  SUBMIT_ORDER_FULFILLED: (state, action) => {
    state.order.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.order.data = _.get(action, 'payload.data.data.items.id');
  },
  SUBMIT_ORDER_REJECTED: (state, action) => {
    state.order.status = {
      loading: false,
      error: _.get(action, 'payload.data.errors', [
        [
          'Sorry something went wrong on our end. We are currently trying to fix the problem. If the error continues, please contact support@savewatersavemoney.com',
        ],
      ]),
      done: false,
    };
  },

  PRE_CHECKOUT_PENDING: state => {
    state.preCheckout.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  PRE_CHECKOUT_FULFILLED: (state, action) => {
    state.preCheckout.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.preCheckout.creditAudit = {};
    state.preCheckout.basketRejected = _.get(action, 'payload.data.basketRejected', false);
  },

  PRE_CHECKOUT_REJECTED: (state, action) => {
    state.preCheckout.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.preCheckout.creditAudit = _.get(action, 'payload.data.creditAudit');
    state.preCheckout.basketRejected = _.get(action, 'payload.data.basketRejected', false);
  },

  GET_ADDRESSES_PENDING: state => {
    state.addresses.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  GET_ADDRESSES_FULFILLED: (state, action) => {
    state.addresses.data = _.get(action, 'payload.data.data.items', []);
  },

  GET_ADDRESSES_REJECTED: state => {
    state.addresses.status = {
      loading: false,
      error: true,
      done: false,
    };
  },

  GET_FREE_PRODUCTS_FULFILLED: (state, action) => {
    const groups: any = [];
    _.forEach(_.get(action, 'payload.data.data.items[0]groups', []), group => {
      const { name, limit, limitRule, products } = group;
      groups.push({
        name,
        limit,
        limitRule,
        products: _.map(products, product => product.id),
      });
    });
    const productsEntity = new schema.Entity('products');
    const appSchema = new schema.Array(productsEntity);
    const allProducts: any = [];
    _.forEach(_.get(action, 'payload.data.data.items[0]groups', []), group => {
      allProducts.push(...group.products);
    });
    const normalizedData: any = normalize(allProducts, appSchema);

    state.products.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.products.data = normalizedData;
    state.products.groups = groups;
  },
  GET_FREE_PRODUCTS_REJECTED: state => {
    state.products.status = {
      loading: false,
      error: true,
      done: false,
    };
  },
});

export default pageTipsReducer;
