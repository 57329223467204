import { connect } from 'react-redux';
import Main from './Main';
import { setComponentWidth, getConfig } from '../../../actions';
import { AppState } from '../../../interfaces';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state: AppState) => {
  return {
    app: state.appReducer,
    appWidth: state.uiReducer.appWidth,
    user: state.userReducer,
    userLocation: state.locationReducer,
  };
};
const mapDispatchToProps = dispatch => ({
  setComponentWidth: appWidth => dispatch(setComponentWidth(appWidth)),
  getConfig: () => dispatch(getConfig()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
