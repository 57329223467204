import styled, { css } from 'styled-components';
import { colors } from '../../../assets/variables';
const Wrapper = styled.div`
  color: ${colors.primary};
  line-height: 1.3;
  a {
    text-decoration: none;
    color: ${colors.primary};
    font-weight: 700;
    &:hover {
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-decoration-color: #25b3eb;
      text-underline-offset: 5px;
    }
  }
  h1 {
    font-size: 28px;
    font-weight: 700;
    margin: 0 0 40px 0;
  }
  h2 {
    font-size: 24px;
    font-weight: 700;
  }
  h3 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 16px 0;
  }
  h4 {
    font-size: 18px;
    font-weight: 700;
  }

  p {
    font-size: 16px;
    font-weight: 400;
  }
  ${props => {
    if (props.isDesktop) {
      return desktopStyles;
    }
  }};
`;

const desktopStyles = css`
  h1 {
    font-size: 40px;
    font-weight: 700;
    margin: 0 0 48px 0;
  }
  h2 {
    font-size: 32px;
    font-weight: 700;
  }
  h3 {
    font-size: 26px;
    font-weight: 700;
    margin: 0 0 24px 0;
  }
  h4 {
    font-size: 20px;
    font-weight: 700;
  }
`;
const Textarea = styled.textarea`
  font-family: 'Open Sans', sans-serif;
  border: 2px solid ${colors.primary};
  border-radius: 10px;
  padding: 8px;
`;
export default { Wrapper, Textarea };
