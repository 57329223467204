import React from 'react';
import StepNav from '../../StepNav';
import {
  CounterControl,
  // ButtonsControl,
  SliderControl,
  InputControl,
  SicCodesControl,
  SelectControl,
  RadioControl,
  CheckboxControl,
} from '../../InputControls';
// import Typography from '../../Typography';
import { QuestionProps } from '../../../interfaces';
import Styled from './Question.style';
import { Icon } from '@savewatersavemoney/swsm-ui';
import { addGroup } from '../../../helpers';
import { seConstants } from '../../../constants';
import Typography from '../../Typography';
import i18next from 'i18next';

class Question extends React.Component<QuestionProps, any> {
  private questionRef = React.createRef<HTMLDivElement>();

  // constructor(props: any) {
  //   super(props);
  // }

  componentDidMount() {
    const { qaProgress, currentStep, postProgress } = this.props;
    if (this.questionRef.current && this.props.containerHeight !== this.questionRef.current.clientHeight) {
      this.updateQuestionHeight();
    }
    if (currentStep === 1) {
      return postProgress(qaProgress, seConstants.START_TRACKING);
    }
  }

  componentDidUpdate() {
    if (this.questionRef.current && this.props.containerHeight !== this.questionRef.current.clientHeight) {
      this.updateQuestionHeight();
    }
  }

  updateQuestionHeight() {
    if (this.questionRef.current) {
      this.props.setQuestionHeight(this.questionRef.current.clientHeight);
    }
  }

  trackProgress = () => {
    const { qaProgress, postProgress } = this.props;
    return postProgress(qaProgress, seConstants.TRACKING);
  };

  handleNext = () => {
    const { controls } = this.props.data;
    if (controls) {
      controls.forEach(control => {
        const { key, defaultValue } = control;
        // IF slider || counter || input -> submit default values when go NEXT
        if (defaultValue) {
          if (!(key in this.props.answers)) {
            // update as Number type
            this.props.updateAnswerKey(key, defaultValue);
            // if (type === 'input' || type === 'slider' || type === 'counter') {
            //   this.props.updateAnswerKey(key, parseInt(defaultValue, 10));
            // }
            // // update as String type
            // if (type === 'select') {
            //   this.props.updateAnswerKey(key, defaultValue);
            // }
          }
        }
      });
    }
    this.trackProgress();
    this.props.goToNext();
  };

  handlePrev = e => {
    e && e.preventDefault();
    this.props.goToPrev();
  };

  controllsHandler = () => {
    const { controls, color_theme } = this.props.data;
    const {
      setNavNextVisibility,
      conditionalKeysVisibility,
      updateAnswerKey,
      answers,
      // modalShow,
      validateRequiredKeys,
      currentStep,
    } = this.props;
    if (controls) {
      return addGroup(controls).map(control => {
        const {
          type,
          key,
          min,
          grouped,
          max,
          inputType,
          options,
          text,
          desc,
          // helpTitle,
          size,
          defaultValue,
          required,
          help_image_url,
          placeholder,
        } = control;

        const sharedProps = {
          answerKey: key,
          key,
          updateAnswerKey,
          currentStep,
          value: answers[key],
        };

        if (
          conditionalKeysVisibility &&
          conditionalKeysVisibility.hasOwnProperty(key) &&
          conditionalKeysVisibility[key] === false
        ) {
          return null;
        }

        switch (type) {
          case 'counter':
            return (
              <CounterControl
                {...sharedProps}
                answers={answers}
                color_theme={color_theme}
                controls={controls}
                defaultValue={defaultValue}
                desc={desc}
                help_image_url={help_image_url}
                max={max}
                min={min}
                validateRequiredKeys={validateRequiredKeys}
                grouped={grouped}
              />
            );
          case 'buttons':
            return (
              <RadioControl
                {...sharedProps}
                required={required}
                goToNext={this.handleNext}
                updateAnswerKey={updateAnswerKey}
                text={i18next.t(text)}
                options={options}
              />
            );
          case 'input':
            return (
              <InputControl
                {...sharedProps}
                key={key}
                max={max}
                min={min}
                inputType={inputType}
                required={required}
                placeholder={placeholder}
                setNavNextVisibility={setNavNextVisibility}
                validateRequiredKeys={validateRequiredKeys}
              />
            );
          case 'sic_codes':
            return (
              <SicCodesControl
                {...sharedProps}
                max={max}
                min={min}
                size={size}
                options={options}
                setNavNextVisibility={setNavNextVisibility}
              />
            );
          case 'select':
            return (
              <SelectControl
                {...sharedProps}
                defaultValue={defaultValue}
                options={options}
                setNavNextVisibility={setNavNextVisibility}
                size={size}
                validateRequiredKeys={validateRequiredKeys}
                placeholder={placeholder}
              />
            );
          case 'slider':
            return (
              <SliderControl
                {...sharedProps}
                defaultValue={defaultValue}
                help_image_url={help_image_url}
                max={max}
                min={min}
                required={required}
                setNavNextVisibility={setNavNextVisibility}
                sliderIndicatorText={desc}
                validateRequiredKeys={validateRequiredKeys}
              />
            );
          case 'checkbox':
            return (
              <CheckboxControl
                {...sharedProps}
                required={required}
                updateAnswerKey={updateAnswerKey}
                validateRequiredKeys={validateRequiredKeys}
                text={i18next.t(text)}
                desc={desc}
              />
            );

          default:
            return <div key={key}>Controls for {this.props.questiontype} not exist</div>;
        }
      });
    }

    return null;
  };

  handleStepnav = () => {
    const { currentStep, navNextVisible } = this.props;
    return (
      <StepNav
        handlePrev={currentStep !== 0 && currentStep !== 1 ? this.handlePrev : undefined} //Hide if 1st question
        handleNext={navNextVisible && this.handleNext}
      />
    );
  };

  render() {
    const { id, title, icon, text } = this.props.data;
    const title_key = i18next.t(title);
    const text_key = i18next.t(text);
    return (
      <Styled.Wraper ref={this.questionRef}>
        <Styled.Container id={id}>
          <Typography size="3" text={title_key} />
          {/* <Styled.Title>{title}</Styled.Title> */}
          {/* <Typography size="2" text={text} /> */}
          <Styled.Question style={{ fontSize: '16px' }}>{text_key}</Styled.Question>
          <Styled.Icon>{icon && <Icon icon={icon} size={150} />}</Styled.Icon>
          <div style={{ maxWidth: '360px', margin: 'auto', width: '100%' }}>{this.controllsHandler()}</div>
          {this.handleStepnav()}
        </Styled.Container>
      </Styled.Wraper>
    );
  }
}

export default Question;
