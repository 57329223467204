import { CompetitionsPageReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
import * as _ from 'lodash';
const initialState: CompetitionsPageReducer = {
  status: {
    loading: false,
    error: false,
    done: false,
  },
  donateStatus: {
    loading: false,
    error: false,
    done: false,
  },
  data: null,
};

const pageCompetitionsReducer = createReducer(initialState, {
  GET_PAGE_COMPETITIONS_PENDING: state => {
    state.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  GET_PAGE_COMPETITIONS_FULFILLED: (state, action) => {
    state.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.data = action.payload.data;
  },

  GET_PAGE_COMPETITIONS_REJECTED: (state, action) => {
    state.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.data = action.payload.data;
  },
  DONATE_LITRES_PENDING: state => {
    state.donateStatus = {
      loading: true,
      error: false,
      done: false,
    };
  },

  DONATE_LITRES_FULFILLED: (state, action) => {
    const id = _.get(action, 'meta.team_id');

    state.donateStatus = {
      loading: false,
      error: false,
      done: true,
    };

    if (state.data) {
      state.data.teamSupported = id;
    }
  },

  DONATE_LITRES_REJECTED: state => {
    state.donateStatus = {
      loading: false,
      error: true,
      done: false,
    };
  },
});

export default pageCompetitionsReducer;
