import styled from 'styled-components';

import { Col } from 'react-styled-flexboxgrid';

const Wrap = styled.div`
  display: block;
  margin-bottom: 32px;
  font-family: 'Open sans', sans-serif;
`;
const ToggleWrap = styled.div`
  height: 38px;
  width: 140px;
  border: 2px solid #174489;
  border-radius: 20px;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-bottom: 16px;
`;
const Item = styled.div`
  padding: 8px;
  width: 90px;
  cursor: pointer;
  text-align: center;
  border-right: 1px solid #174489;
  &:last-child {
    border-right: 0px;
  }
  &.active {
    background: #174489;
    color: white;
  }
  &.inactive {
    color: #174489;
    background: white;
  }
`;
const Column = styled(Col)`
  margin-bottom: 32px;

  @media screen and (max-width: 700px) {
    padding: 0;
  }
`;

export default { Wrap, Column, ToggleWrap, Item };
