import * as _ from 'lodash';
import { LocationReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
import { getVariable } from '../helpers';
import i18next from 'i18next';

export const initialState: LocationReducer = {
  status: {
    loading: false,
    error: null,
    done: false,
  },
  data: null,
};

const locationReducer = createReducer(initialState, {
  GET_LOCATION_PENDING: state => {
    state.status = {
      loading: true,
      error: null,
      done: false,
    };
  },

  GET_LOCATION_FULFILLED: (state, action) => {
    const locationData = _.get(action, 'payload.data.data', {});
    let redirectURL;
    const swsmInternal = getVariable('mode', 'swsm', false);

    if (locationData.postcode && locationData.is_redirect_require) {
      redirectURL = locationData.site_url;
    }
    return {
      ...state,
      status: {
        loading: false,
        error: null,
        done: true,
      },
      data: locationData,
      redirect_require: locationData.is_redirect_require && !swsmInternal,
      redirect_url: redirectURL,
      waterClient: locationData.water_client,
    };
  },

  LOCATION_ERROR: (state, action) => {
    state.status = {
      loading: false,
      error: _.get(action, 'msg', i18next.t('portal.general.error_message')),
      done: true,
    };
  },

  GET_LOCATION_REJECTED: (state, action) => {
    state.status = {
      loading: false,
      error: _.get(action, 'payload.data.errors[0]', i18next.t('portal.general.error_message')),
      done: true,
    };
  },
});

export default locationReducer;
