import React from 'react';
import Styled from './NothingToDisplay.style';
import { Icon } from '@savewatersavemoney/swsm-ui';
import { useTranslation } from 'react-i18next';

interface NothingToDisplay {
  type?: string;
  title?: string;
  subtitle?: string;
  icon?: string;
}

const NothingToDisplay = props => {
  const { type, title, subtitle, icon } = props;
  const [t] = useTranslation('common');
  const noData = t('portal.no_data.title');
  const noDatadesc = t('portal.no_data.description');
  const noDatatitle = t('portal.no_data.we_have_problem');
  const noDatadesc2 = t('portal.no_data.we_have_problem_desc');
  return type === 'chart' ? (
    <Styled.WrapSmall>
      <Icon icon="chart" size={60} />
      <h3>{noData}</h3>
      <div>{noDatadesc}</div>
    </Styled.WrapSmall>
  ) : (
    <Styled.Wrap>
      <Icon icon={icon ? icon : 'alert'} size={100} />
      <h3>{title ? title : noDatatitle}</h3>
      <div>{subtitle ? subtitle : noDatadesc2}</div>
    </Styled.Wrap>
  );
};

export default NothingToDisplay;
