import { NotificationsReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

const initialState: NotificationsReducer = {
  getNotifications: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  readNotification: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
};

const notificationsReducer = createReducer(initialState, {
  GET_NOTIFICATIONS_PENDING: state => {
    state.getNotifications.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  GET_NOTIFICATIONS_FULFILLED: (state, action) => {
    state.getNotifications.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.getNotifications.data = action.payload.data;
  },

  GET_NOTIFICATIONS_REJECTED: (state, action) => {
    state.getNotifications.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.getNotifications.data = action.payload.data;
  },
  READ_NOTIFICATION_PENDING: state => {
    state.readNotification.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  READ_NOTIFICATION_FULFILLED: (state, action) => {
    state.readNotification.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.readNotification.data = action.payload.data;
  },

  READ_NOTIFICATION_REJECTED: (state, action) => {
    state.readNotification.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.readNotification.data = action.payload.data;
  },
});

export default notificationsReducer;
