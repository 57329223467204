import styled from 'styled-components';
import { Button } from '@savewatersavemoney/swsm-ui';

// import ButtonComponent from '../Button';
import { colors } from '../../assets/variables';

export const CloseButton = styled(Button)`
  position: relative;
  top: 20px;
  left: 15px;
`;

export const Modal = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  overflow: auto;
  z-index: 9999;
  top: 0;
  left: 0;
`;

export const ModalContent = styled.div`
  max-width: 440px;
  margin: auto;
  text-align: center;
  margin-top: 20px;
  padding: 0 10px;
  p {
    font-family: 'Open sans', sans-serif;
  }
`;

export const ModalTitle = styled.div`
  font-weight: bolder;
  margin-bottom: 20px;
  font-family: 'Open sans', sans-serif;
`;

export const ModalResetButton = styled(Button)`
  text-align: center;
  display: block;
  margin: auto;
  width: 280px;
  margin-bottom: 10px;
  font-family: 'Open sans', sans-serif;
`;

export const ModalHelpContent = styled.div`
  margin-bottom: 30px;
`;

export const ModalHelpContentButton = styled(Button)`
  margin-bottom: 30px;
`;

export const ModalHelpText = styled.div`
  margin-bottom: 10px;
`;

export const ModalHelpImage = styled.img`
  width: 200px;
  display: block;
  margin: auto;
  margin-bottom: 10px;
  border: 1px solid ${colors.disabled};
`;
