import styled from 'styled-components';
import { swsmTransition } from '../../assets/SharedStyles.style';

const DebugBar = styled.div`
  top: 0;
  left: -500px;
  position: fixed;
  height: 100vh;
  width: 500px;
  background-color: #fffdf4;
  z-index: 999;

  #debug-search {
    padding: 10px 10px;
    box-shadow: -5px 1px 5px rgba(0, 0, 0, 0.6);

    input {
      margin-bottom: 0;
    }
  }

  .debug-ins {
    overflow: auto;
    height: calc(100% - 57px);
    &:focus {
      border: 1px solid black;
    }
  }

  ${swsmTransition('left')}

  &.open {
    ${swsmTransition('left')}
    left: 0;
  }

  .debug-toggle {
    position: absolute;
    left: 100%;
    bottom: 10px;
  }

  .debugRow {
    padding: 0 20px;
    box-shadow: inset 0 2px 0px -1px #cacaca;
    position: relative;
    opacity: 0;
    height: 0;
    overflow: hidden;
    transform: scale(0.4) rotate(10deg) rotateY(40deg);

    ${swsmTransition('all')}

    &.visible {
      padding: 20px;
      height: auto;
      opacity: 1;
      ${swsmTransition('all')}
      transform: scale(1) rotate(0) rotateY(0);
    }

    &__index {
      position: absolute;
      left: 2px;
      top: 2px;
      color: white;
      background-color: orange;
      font-size: 13px;
      padding: 1px 6px;
      border-radius: 40px;
    }

    &__modal {
      position: absolute;
      right: 0;
      top: 1px;
      font-size: 12px;
      background-color: #b1d3ff;
      padding: 3px 10px;
    }

    &__icon {
      float: left;
    }

    &__text {
      margin-left: 60px;
      font-size: 12px;
      min-height: 60px;
    }

    &__keys {
      margin-top: 10px;
    }

    &.active {
      background-color: #ffecc7;
    }

    &__logic {
      margin-top: 10px;
      &__or {
        position: relative;
        background-color: #ffcbf2;
        font-size: 12px;
        padding: 2px;
        margin-bottom: 2px;
        display: inline-block;
        margin-right: 20px;
        &:after {
          right: -16px;
          font-weight: bold;
          font-size: 10px;
          position: absolute;
          bottom: 3px;
          content: 'OR';
        }
        &:last-child {
          &:after {
            content: none;
          }
          margin-bottom: 0;
        }
      }

      &__and {
        position: relative;
        background-color: #ffcbf2;
        font-size: 12px;
        padding: 2px;
        margin-bottom: 2px;
        display: inline-block;
        margin-right: 30px;
        &:after {
          right: -24px;
          font-weight: bold;
          font-size: 10px;
          position: absolute;
          bottom: 3px;
          content: 'AND';
        }
        &:last-child {
          &:after {
            content: none;
          }
          margin-bottom: 0;
        }
      }
    }
  }

  .answerKeys {
    list-style: none;
    margin: 0;
    font-size: 16px;

    li {
      background-color: #e6e6e6;
      margin-bottom: 4px;
    }

    .key {
      display: inline-block;
      padding: 2px 10px;
      background-color: purple;
      color: white;
    }

    .empty {
      display: inline-block;
      padding: 2px 10px;
      background-color: #ce0f03;
      color: white;
    }

    .answer {
      display: inline-block;
      padding: 2px 10px;
      background-color: green;
      color: white;
    }
  }
`;

export default { DebugBar };
