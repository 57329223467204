import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill';
import React, { Suspense } from 'react';
import { render } from 'react-dom';
import AppWrap from './components/AppWrap';
import { Provider } from 'react-redux';
import store from './configureStore';
import * as serviceWorker from './serviceWorker';
import history from './history';
import { ConnectedRouter } from 'connected-react-router';
import SentryBoundary from './SentryBoundary';
import { setupInterceptors, getVariable } from './helpers';
import WebFont from 'webfontloader';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

WebFont.load({
  google: {
    families: ['Open Sans:400,600,700', 'sans-serif'],
  },
});

setupInterceptors();

const rootEl = document.getElementById('root');
const env = getVariable('mode', 'env');
const prodUrl = 'https://cdn.getwaterfit.co.uk/static/files/translations/prod/{{lng}}/translations.json';
const url = `https://cdn.getwaterfit.co.uk/static/files/translations/${env}/{{lng}}/translations-test.json`;
let filePath;

if (env === 'dev' || env === 'stage') {
  filePath = url;
}
else {
  filePath = prodUrl;
}

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en-GB',
    lng: 'en-GB',
    load: 'currentOnly',
    backend: {
      loadPath: filePath,
    },
    react: {
      useSuspense: true,
    },
    detection: {
      order: ['localStorage', 'cookie', 'querystring', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage'],
    },
  })
  .then(() => {})
  .catch(err => {
    console.log('i18 Error', err);
  });

const renderApp = () =>
  render(
    <Provider store={store}>
      <SentryBoundary>
        <ConnectedRouter history={history}>
          <I18nextProvider i18n={i18next}>
            <Suspense fallback={<div>Loading...</div>}>
              <AppWrap />
            </Suspense>
          </I18nextProvider>
        </ConnectedRouter>
      </SentryBoundary>
    </Provider>,
    rootEl,
  );

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./components/AppWrap', renderApp);
}

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
