import Loadable from 'react-loadable';

const Header = Loadable({
  loader: () => import('./HeaderContainer'),
  loading() {
    return null;
  },
});

export default Header;
