import React, { Component } from 'react';
import Select from 'react-virtualized-select';
import { SelectControlProps } from '../../../interfaces';
import Styled from './SelectControl.style';

// can NOT be combined with others controls
// need interaction to proceed to next step
// need function set up answer
// need to call function to set visible next nav
// need to be able prepare view just by one answer key
// need to be able to hide next nav if not valid answer on not complete

interface SelectControlState {
  defaultValue: string;
  value: string | null;
}

class SelectControl extends Component<SelectControlProps, SelectControlState> {
  static defaultProps = {
    value: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      defaultValue: props.defaultValue ? props.defaultValue : null,
      value: props.value,
    };
    this.generateOptions = this.generateOptions.bind(this);
  }

  handleChange = selectedOption => {
    const { setNavNextVisibility, answerKey, updateAnswerKey = () => void 0 } = this.props;

    if (selectedOption) {
      this.setState({ value: selectedOption.value });
      updateAnswerKey(answerKey, selectedOption.value);

      setNavNextVisibility(true);
    }
    else {
      this.setState({ value: null });
      updateAnswerKey(answerKey, null);
      setNavNextVisibility(false);
    }
  };

  generateOptions() {
    const { options } = this.props;
    return options.map(option => {
      return {
        value: option.value,
        label: option.text,
      };
    });
  }

  render() {
    const { defaultValue, value } = this.state;
    const { size } = this.props;

    return (
      <Styled.Wraper size={size}>
        <Select
          value={value || defaultValue}
          onChange={this.handleChange}
          options={this.generateOptions()}
          optionHeight={50}
          placeholder={this.props.placeholder || 'Select...'}
        />
      </Styled.Wraper>
    );
  }
}

export default SelectControl;
