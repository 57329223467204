import React from 'react';
import { Tile } from '@savewatersavemoney/swsm-ui';
import { Col, Row } from 'react-styled-flexboxgrid';
import history from '../../../history';
import { useTranslation } from 'react-i18next';

const PageOverviewBanners: React.FC<any> = props => {
  const { isDesktop, videoLibrary } = props;
  const [t] = useTranslation('common');

  const banners = [
    {
      title: t('portal.home.myuse_banner_title'),
      theme: 'darkTheme',
      description: t('portal.home.myuse_banner_description'),
      isDesktop,
      bgPosition: isDesktop ? '-80px' : '0',
      onButtonClick: () => {
        history.push('/myuse');
      },
      display: true,
      buttonText: t('portal.home.myuse_banner_buttonText'),
      imageUrl: 'https://cdn.getwaterfit.co.uk/static/files/images/develop/component/my-use.webp',
    },
    {
      title: t('portal.home.easywins_banner_title'),
      theme: 'darkTheme',
      description: t('portal.home.easywins_banner_descripiton'),
      isDesktop,
      bgPosition: isDesktop ? '-20px' : '0',
      onButtonClick: () => {
        history.push('/easywins');
      },
      display: true,
      buttonText: t('portal.home.easywins_banner_buttonText'),
      imageUrl: 'https://cdn.getwaterfit.co.uk/static/files/images/develop/component/challenges.webp',
    },
    {
      title: t('portal.home.askme_banner_title'),
      theme: 'darkTheme',
      description: t('portal.home.askme_banner_descripiton'),
      isDesktop,
      bgPosition: isDesktop ? '-90px' : '0',
      onButtonClick: () => {
        history.push('/contact-us');
      },
      display: true,
      buttonText: t('portal.home.askme_banner_buttonText'),
      imageUrl: 'https://cdn.getwaterfit.co.uk/static/files/images/develop/component/ask-me.webp',
    },
    {
      title: t('portal.customer_service.video_library2'),
      theme: 'darkTheme',
      description: t('portal.customer_service.video_library_description2'),
      isDesktop,
      bgPosition: isDesktop ? '-90px' : '0',
      onButtonClick: () => {
        history.push('/video-library');
      },
      display: videoLibrary,
      buttonText: t('portal.customer_service.video_library_button'),
      imageUrl: 'https://cdn.getwaterfit.co.uk/static/files/images/develop/component/book-va.webp',
    },
  ];

  return (
    <Row>
      {banners.map((bannerProps, index) => {
        if (bannerProps.display) {
          return (
            <Col xs={12} sm={12} md={6} key={`key${index}`}>
              <Tile {...bannerProps} />
            </Col>
          );
        }
        return null;
      })}
    </Row>
  );
};

export default PageOverviewBanners;
