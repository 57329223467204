import styled, { css } from 'styled-components';
import StyledQuestion from '../Question/Question.style';
import StyledTypography from '../../Typography/Typography.style';
import { colors } from '../../../assets/variables';
const Wraper = styled(StyledQuestion.Wraper)`
  ${props => {
    if (props.isDesktop) {
      return deskTopStyles;
    }
  }}
`;

const Container = styled(StyledQuestion.Container)``;

const Title = styled(StyledQuestion.Title)``;

const Question = styled(StyledTypography.QAHeadTwo)``;
const ImageContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
  p {
    color: ${colors.primary};
    margin-top: 0;
  }
`;
const ImageWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Image = styled.img`
  padding: 0 10px;
  width: 120px;
`;
const BusinessLink = styled.div`
  p {
    margin: 0;
    color: ${colors.primary};
  }
  a {
    color: ${colors.action};
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;
const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  width: 500px;
`;
const IconGrid = styled.div`
  display: grid;
  width: 600px;
  margin: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 0px;
  padding: 10px;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
    width: 100%;
    padding: 0;
    margin-bottom: 40px;
  }
`;
// const IconItem = styled.div`
//   border-bottom: 1px solid #d7f1ff;
//   border-top: none;
//   position: relative;
//   border-radius: 0;
//   padding: 16px;
//   align-items: center;
//   justify-content: center;
//   display: flex;
//   flex-direction: column;
//   &:last-child {
//     border-bottom: none;
//   }
//   &:nth-last-child(2) {
//     border-bottom: none;
//   }
//   &:nth-last-child(3) {
//     border-bottom: none;
//   }

//   &:nth-child(3n-1) {
//     border-left: 1px solid #d7f1ff;
//     border-right: 1px solid #d7f1ff;
//   }
//   @media screen and (max-width: 600px) {
//     &:last-child {
//       border-bottom: none;
//     }
//     &:nth-last-child(2) {
//       border-bottom: none;
//     }
//     &:nth-child(2n + 1) {
//       border-right: 1px solid #d7f1ff;
//     }
//   }
// `;
const BoxTitle = styled.h3`
  min-height: 50px;
  @media screen and (max-width: 420px) {
    font-size: 14px;
    min-height: 38px;
  }
`;
const deskTopStyles = css`
  ${Image} {
    width: 180px;
  }
`;

export default {
  Wraper,
  Container,
  Image,
  ImageWrap,
  ImageContainer,
  BusinessLink,
  Title,
  Question,
  IconWrap,
  IconGrid,
  BoxTitle,
};
