const endpointReplace = config => {
  // GLOBAL-- - GLOBAL-- - GLOBAL-- - GLOBAL-- - GLOBAL-- - GLOBAL-- -

  // if (
  //   config.url ===
  //   'https://v8ms.stage.savewatersavemoney.co.uk/portal/api/1.0/saving-rewards'
  // ) {
  //   // config.url = 'http://localhost:3022/savingRewards';
  //   config.url = 'https://api.jsonbin.io/b/5e4272b09c65d21641a9a1a1';
  // }

  // if (
  //   config.url ===
  //   'https://v8ms.stage.savewatersavemoney.co.uk/portal/api/1.0/saving/how-do-i-earn-coins'
  // ) {
  //   // config.url = 'http://localhost:3022/saving--how-do-i-earn-coins';
  //   config.url = 'https://api.jsonbin.io/b/5e42739e9c65d21641a9a203';
  // }

  // if (
  //   config.url ===
  //   'https://v8ms.stage.savewatersavemoney.co.uk/portal/api/1.0/saving/history'
  // ) {
  //   // config.url = 'http://localhost:3022/saving--history';
  //   config.url = 'https://api.jsonbin.io/b/5e4273ffd18e401661768f6a';
  // }

  // if (config.url === 'https://v8ms.stage.savewatersavemoney.co.uk/gamification/api/1.0/leaderboard') {
  //   config.url = 'http://localhost:3022/saving--leaderboard';
  //   config.url = 'https://api.jsonbin.io/b/5e60c83774ed8a66ce71a05d';
  // }
  // if (config.url === 'https://api.dev.getwaterfit.co.uk/gamification/api/1.0/competitions') {
  // config.url = 'http://localhost:3066/#/saving--competitions';
  // config.url = 'https://api.jsonbin.io/b/5e60d337baf60366f0e38fa5/2';
  // }

  // if (
  //   config.url ===
  //   'https://v8ms.stage.savewatersavemoney.co.uk/portal/api/1.0/general'
  // ) {
  //   // config.url = 'http://localhost:3022/general';
  //   config.url = 'https://api.jsonbin.io/b/5e4282df817c5f163f9a7e5d';
  // }

  // DEV-- - DEV-- - DEV-- - DEV-- - DEV-- - DEV-- - DEV-- - DEV-- - DEV-- -
  if (process.env.NODE_ENV !== 'production') {
    // if (
    //   config.url ===
    //   'https://v8ms.stage.savewatersavemoney.co.uk/portal/api/1.0/saving'
    // ) {
    //   config.url = 'http://localhost:3022/saving';
    // }
    // if (
    //   config.url ===
    //   'https://v8ms.stage.savewatersavemoney.co.uk/portal/api/1.0/usage'
    // ) {
    //   config.url = 'http://localhost:3022/usage';
    // }
    // if (
    //   config.url ===
    //   'https://v8ms.stage.savewatersavemoney.co.uk/portal/api/1.0/overview'
    // ) {
    //   config.url = 'http://localhost:3022/overview';
    // }
    // if (
    //   config.url ===
    //   'https://v8ms.stage.savewatersavemoney.co.uk/portal/api/1.0/compare'
    // ) {
    //   config.url = 'http://localhost:3022/compare';
    // }
  }

  return config;
};

export default endpointReplace;
