import React from 'react';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import App from '../App';
import { setComponentWidth } from '../../actions/index';
import { refreshAccessToken, unlockApp } from '../../actions/actionsAuth';
import { getVariable } from '../../helpers';
import { AppState } from '../../interfaces';

class AppWrap extends React.Component<any, any> {
  private componentRef = React.createRef<HTMLDivElement>();
  private swsmInternal = getVariable('mode', 'swsm', false);

  constructor(props) {
    super(props);
    this.revalidateToken();
  }

  revalidateToken = () => {
    this.props.unlockApp();
  };

  componentDidMount() {
    this.updateWidth(this.props.appWidth);
    window.addEventListener('resize', this.debounceWidth);
    window.addEventListener('error', this.detectUpdate, true);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debounceWidth);
    window.removeEventListener('error', this.detectUpdate, true);
  }

  componentDidUpdate(prevProps) {
    this.updateWidth(prevProps.appWidth);
    this.revalidateToken();
  }

  updateWidth(appWidth) {
    if (this.componentRef.current && !this.swsmInternal) {
      if (appWidth !== this.componentRef.current.clientWidth) {
        this.props.setComponentWidth(this.componentRef.current.clientWidth);
      }
    }
  }

  debounceWidth = _.debounce(() => {
    this.updateWidth(this.props.appWidth);
  }, 300);

  detectUpdate(e) {
    if (_.includes(e.target.src, '.chunk.js')) {
      console.info('Update released... reloading');
      window.location.reload();
    }
  }

  render() {
    const { lockApp } = this.props;

    if (lockApp) {
      return null;
    }

    return (
      <div ref={this.componentRef}>
        <App />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setComponentWidth: appWidth => dispatch(setComponentWidth(appWidth)),
  refreshAccessToken: () => dispatch(refreshAccessToken()),
  unlockApp: () => dispatch(unlockApp()),
});

const mapStateToProps = (state: AppState) => ({
  user: state.userReducer,
  lockApp: state.appReducer.lockApp,
});

export default connect(mapStateToProps, mapDispatchToProps)(AppWrap);
