import React from 'react';
import { Grid } from 'react-styled-flexboxgrid';
import Styled from './RenderContainer.style';
import { getVariable } from '../../helpers';
interface RenderContainerProps {
  children: any;
  id?: string;
  style?: any;
  homepage?: boolean;
  env: any;
}

const RenderContainer: React.FC<RenderContainerProps> = props => {
  const { children, style, id, homepage } = props;
  const isInternal = getVariable('mode', 'swsm', false);
  return (
    <Styled.Wrapper homepage={homepage} env={props.env} style={style} id={id}>
      {isInternal ? <Grid>{children}</Grid> : children}
    </Styled.Wrapper>
  );
};

export default RenderContainer;
