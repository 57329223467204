import styled from 'styled-components';
import { QAcolors } from '../../assets/variables';

const Input = styled.input`
  display: inline-block;
  width: calc(100% - 32px);
  max-width: 380px;
  box-shadow: inset 0 0 0 2px ${QAcolors.primary};
  background-color: white;
  border: 0;
  color: ${QAcolors.primary};
  border-radius: 0px;
  padding: 16px;
  font-size: 16px;
  margin-bottom: 10px;
  border-radius: 6px;
`;

export default { Input };
