import { connect } from 'react-redux';
import ProgressBar from './ProgressBar';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = state => {
  return {
    steps: state.savingengineReducer.steps,
    currentStep: state.savingengineReducer.currentStep,
  };
};

export default connect(mapStateToProps, null)(ProgressBar);
