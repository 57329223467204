import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import Shared from '../../../assets/SharedStyles.style';
import { AuthReducer } from '../../../interfaces';
import ResetPasswordRequest from './ResetPasswordRequest';
import ResetPasswordUpdate from './ResetPasswordUpdate';
import * as qs from 'query-string';

interface ResetPasswordProps {
  authReducer: AuthReducer;
  location: any;
  resetPasswordUpdate: Function;
  resetPasswordRequest: Function;
  resetPasswordRequestRepeat: Function;
}

const ResetPassword: React.FC<ResetPasswordProps> = props => {
  const { resetPasswordRequest, resetPasswordUpdate, resetPasswordRequestRepeat, authReducer } = props;
  const queryString = props.location.search;
  const urlParams = qs.parse(queryString);
  const resetToken = urlParams.token;
  const { resetRequest, resetUpdate } = authReducer;

  return (
    <Row style={{ marginTop: '128px' }}>
      <Col xs={12}>
        <div style={{ height: 'auto', marginBottom: 100 }}>
          <Shared.Box>
            {resetToken ? (
              <ResetPasswordUpdate
                resetUpdate={resetUpdate}
                resetPasswordUpdate={resetPasswordUpdate}
                resetToken={resetToken}
              />
            ) : (
              <ResetPasswordRequest
                resetRequest={resetRequest}
                resetPasswordRequestRepeat={resetPasswordRequestRepeat}
                resetPasswordRequest={resetPasswordRequest}
              />
            )}
          </Shared.Box>
        </div>
      </Col>
    </Row>
  );
};

export default ResetPassword;
