import { AppState } from '../../interfaces';
import { connect } from 'react-redux';
import FooterInfo from './FooterInfo';
import { getInfo } from '../../actions/rewardProgram';

const mapStateToProps = (state: AppState) => ({
  page: state.getInfoReducer,
});

const mapDispatchToProps = dispatch => ({
  getInfo: data => dispatch(getInfo(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FooterInfo);
