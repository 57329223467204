import { connect } from 'react-redux';
import ContactForm from './ContactForm';
import { AppState } from '../../interfaces';
import { sendContactUs } from '../../actions/rewardProgram';

const mapStateToProps = (state: AppState) => ({
  contactForm: state.contactFormReducer,
  appWidth: state.uiReducer.appWidth,
});

const mapDispatchToProps = dispatch => ({
  sendContactUs: (data, onSucess, onFailed) => dispatch(sendContactUs(data, onSucess, onFailed)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
