import React from 'react';
import { actionsSavingEngine } from '../../actions/qaIndex';
import { connect } from 'react-redux';
import {
  CloseButton,
  Modal,
  ModalContent,
  ModalTitle,
  ModalHelpText,
  ModalHelpImage,
  ModalHelpContent,
  ModalHelpContentButton,
} from './Modal.styles';
import { colors } from '../../assets/variables';
import { Icon } from '@savewatersavemoney/swsm-ui';

const ModalMultipleHelp = props => {
  const updateAndGoNext = answer => {
    props.updateAnswerKey(props.answerKey, answer);
    props.modalHide();
    props.goToNext();
  };

  const helpContent = props.options.map(option => {
    // SKIP MODAL TRIGGER BUTTON
    if (option.value !== 'help_modal' && (option.help_text || option.help_image_url)) {
      return (
        <ModalHelpContent key={option.value}>
          {option.help_image_url ? <ModalHelpImage src={option.help_image_url} alt="" /> : ''}
          {option.help_text ? <ModalHelpText>{option.help_text}</ModalHelpText> : ''}

          <ModalHelpContentButton
            id={props.answerKey + '--' + option.value}
            colorPrimary={colors[props.color_theme]}
            colorSecondary={colors.baseLight}
            appearance="OUTLINE"
            active={props.value === option.value}
            onClick={() => updateAndGoNext(option.value)}
          >
            {option.text}
          </ModalHelpContentButton>
        </ModalHelpContent>
      );
    }
    return false;
  });

  return (
    <Modal>
      <CloseButton appearance="TRANSPARENT" id="modal_help_close" onClick={props.modalHide}>
        <Icon icon={'times'} size={33} />
      </CloseButton>

      <ModalContent>
        <ModalTitle>{props.helpTitle}</ModalTitle>
        {helpContent}

        {helpContent.every(value => value === false) && <div>Sorry, no help specified</div>}
      </ModalContent>
    </Modal>
  );
};

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return {
    updateAnswerKey: (key, value) => dispatch(actionsSavingEngine.updateAnswerKey(key, value)),
    goToNext: () => dispatch(actionsSavingEngine.goToNext()),
    modalHide: () => dispatch(actionsSavingEngine.modalHide()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalMultipleHelp);
