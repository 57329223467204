import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import * as _ from 'lodash';
import { Wrapper } from './Sidebar.style';
import { AppConfig, AppState } from '../../interfaces';
import Styled from '../../assets/SharedStyles.style';
import LinkHandler from '../../components/LinkHandler';
import { connect } from 'react-redux';
import Typography from '../Typography';
interface SidebarProps {
  appConfig: AppConfig;
  location: any;
}

const Sidebar: React.FC<SidebarProps> = props => {
  const { appConfig } = props;
  const { data } = appConfig;

  if (!data || _.isEmpty(data)) {
    return null;
  }

  const footerNav: any = _.filter(data.navElements, {
    location: 'footer',
  });

  const renderFooterLinks = () => {
    const renderLinks = _.map(footerNav, el => {
      return (
        <div key={el.name}>
          <Wrapper>
            <LinkHandler path={el.path}>{el.title}</LinkHandler>
          </Wrapper>
        </div>
      );
    });
    return (
      <Col xs={12}>
        <Typography size="3" style={{ marginBottom: '16px' }} text="Policies" />
        <Styled.Line />
        {renderLinks}
      </Col>
    );
  };

  return props.location.pathname === '/contact-us' ? (
    <div>{renderFooterLinks()}</div>
  ) : (
    <div>{renderFooterLinks()}</div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    appConfig: state.appReducer.config,
  };
};

export default connect(mapStateToProps)(Sidebar);
