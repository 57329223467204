import { PageProductsFittedReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import i18next from 'i18next';

const initialState: PageProductsFittedReducer = {
  getData: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  setData: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
  },
};

const pageProductsFittedReducer = createReducer(initialState, {
  GET_PRODUCTS_FITTED_PENDING: state => {
    state.getData.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  GET_PRODUCTS_FITTED_FULFILLED: (state, action) => {
    state.getData.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.getData.data = _.get(action, 'payload.data.data', i18next.t('portal.general.error_message'));
  },

  GET_PRODUCTS_FITTED_REJECTED: (state, action) => {
    state.getData.status = {
      loading: false,
      error: _.get(action, 'payload.data.errors', i18next.t('portal.general.error_message')),
      done: false,
    };
  },

  SET_PRODUCTS_FITTED_PENDING: state => {
    state.setData.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  SET_PRODUCTS_FITTED_FULFILLED: state => {
    state.setData.status = {
      loading: false,
      error: false,
      done: true,
    };
  },

  SET_PRODUCTS_FITTED_REJECTED: (state, action) => {
    state.setData.status = {
      loading: false,
      error: _.get(action, 'payload.data.errors', i18next.t('portal.general.error_message')),
      done: false,
    };
  },
});

export default pageProductsFittedReducer;
