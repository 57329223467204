import React from 'react';
// import { colors } from '../../../assets/variables';
import { RadioControlProps } from '../../../interfaces';
import Styled from './RadioControl.style';
import Radio from '../../Radio';
import i18next from 'i18next';

const RadioControl: React.FC<RadioControlProps> = props => {
  const { answerKey, updateAnswerKey = () => void 0, goToNext = () => void 0 } = props;

  const handleAnswer = (value, event) => {
    updateAnswerKey(answerKey, value);
    goToNext(event);
  };
  const renderOptions = props.options.map((option, index) => {
    return (
      <Styled.Row key={index}>
        {option.button_image_url ? <Styled.Image src={option.button_image_url} alt={option.text} /> : null}
        <Radio
          id={answerKey + '--' + option.value}
          name={answerKey + '--' + option.value}
          onChange={event => handleAnswer(option.value, event)}
          aria-label={option.value}
          title={i18next.t(option.text)}
          checked={props.value === option.value}
          value={option.value}
        />
      </Styled.Row>
    );
  });

  return <Styled.Wraper style={{ width: '100%' }}>{renderOptions}</Styled.Wraper>;
};

export default RadioControl;
