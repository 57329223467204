import React from 'react';
import * as _ from 'lodash';
import Styled from './RewardsBanner.style';
import history from '../../history';

interface RewardsBannerProps {
  user?: any;
}
const RewardsBanner: React.FC<RewardsBannerProps> = props => {
  const { user } = props;
  const getRewardBanner = _.get(user, 'data.branding.banner');
  const businessId = _.get(user, 'data.branding.businessId');
  const getBranding = _.get(user, 'data.branding');

  if (!getBranding || getBranding === null || getRewardBanner === null) {
    return null;
  }

  return (
    <div>
      <Styled.BannerWrap>
        <Styled.ImageWrap style={{ backgroundImage: `url('${_.get(getRewardBanner, 'imageURL')}')` }} />
        <Styled.TextWrap>
          <Styled.Title>{_.get(getRewardBanner, 'title')}</Styled.Title>
          <Styled.Description>{_.get(getRewardBanner, 'description')}</Styled.Description>
          <Styled.ButtonWrap>
            <Styled.OptButton onClick={() => history.push(`/myshop/${businessId}`)}>Take me there</Styled.OptButton>
          </Styled.ButtonWrap>
        </Styled.TextWrap>
      </Styled.BannerWrap>
    </div>
  );
};
export default RewardsBanner;
