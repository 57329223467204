import { AppState } from './../../../interfaces';
import { connect } from 'react-redux';
import GenericMarkdown from './GenericMarkdown';
import { setCustomerService } from '../../../actions/rewardProgram';

const mapStateToProps = (state: AppState) => {
  return {
    appWidth: state.uiReducer.appWidth,
    app: state.appReducer,
  };
};

const mapDispatchToProps = dispatch => ({
  setCustomerService: (data, onSucess, onFailed) => dispatch(setCustomerService(data, onSucess, onFailed)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GenericMarkdown);
