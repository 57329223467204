import React, { useEffect } from 'react';
import ExternalLinks from '../../ExternalLinks';
import PageOverviewBanners from './PageOverviewBanners';
import PageOverviewBonusChallenges from './PageOverviewBonusChallenges';
import { Loader } from '@savewatersavemoney/swsm-ui';
import { BasePage } from '../../../interfaces';
import { FooterInfo } from './../../index';

import RewardsBanner from '../../RewardsBanner';
import * as _ from 'lodash';

interface PageOverviewProps extends Omit<BasePage, 'page'> {
  challenges: any;
  clearFilters: Function;
  bonusChallenges: Object;
  getBonusChallenges: Function;
  generalData: any;
}
const PageOverview: React.FC<PageOverviewProps> = props => {
  const { appWidth, getBonusChallenges, clearFilters, challenges, bonusChallenges, generalData } = props;
  const isDesktop = appWidth > 800;

  useEffect(() => {
    if (!challenges.bonusChallengesStatus.done && !challenges.bonusChallengesStatus.loading) {
      getBonusChallenges();
    }
    // clearFilters();
  }, [getBonusChallenges, clearFilters, challenges.bonusChallengesStatus]);

  const marketplaceEnabled = _.get(props, 'app.clientConfig.data.data.is_market_place_available');
  const videoLibrary = _.get(props, 'app.clientConfig.data.data.ask_me_videos_enabled');

  if (generalData.status.loading || challenges.bonusChallengesStatus.loading) {
    return (
      <div style={{ position: 'relative', height: '100px' }}>
        <Loader />
      </div>
    );
  }
  return (
    <>
      <PageOverviewBonusChallenges isDesktop={isDesktop} challenges={challenges} bonusChallenges={bonusChallenges} />
      <RewardsBanner />
      <PageOverviewBanners isDesktop={isDesktop} marketplaceBanner={marketplaceEnabled} videoLibrary={videoLibrary} />

      <ExternalLinks />
      <FooterInfo />
    </>
  );
};
export default PageOverview;
