/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import Typography from '../Typography';
import { GetInfoReducer } from '../../interfaces';
import * as _ from 'lodash';
import { useTranslation, Trans } from 'react-i18next';
import { Col, Row } from 'react-styled-flexboxgrid';

interface FooterInfoProps {
  getInfo: Function;
  page: GetInfoReducer;
}

const FooterInfo: React.FC<FooterInfoProps> = props => {
  const { getInfo, page } = props;
  const [t] = useTranslation('common');

  React.useEffect(() => {
    if (!page.status.done) {
      getInfo();
    }
  }, [getInfo, page.status.done]);

  const waterHeadedWithCoal = _.get(props, 'page.data.data.WATER_HEATED_WITH_COAL_KWH');
  const waterHeadedWithElectric = _.get(props, 'page.data.data.WATER_HEATED_WITH_ELECTRIC_KWH');
  const waterHeadedWithGas = _.get(props, 'page.data.data.WATER_HEATED_WITH_GAS_KWH');
  const waterHeadedWithOil = _.get(props, 'page.data.data.WATER_HEATED_WITH_OIL_KWH');
  const waterHeadedWithRenewable = _.get(props, 'page.data.data.WATER_HEATED_WITH_RENEWABLE_KWH');
  const link = 'http://www.energysavingtrust.org.uk/about-us/our-calculations';

  const text_key = t('portal.home.household_savings');

  return (
    <Row>
      <Col xs={12}>
        <>
          <Typography
            size="p"
            style={{ fontSize: '12px', fontWeight: '400', lineHeight: '18px', marginBottom: '0' }}
            text={text_key}
          />
          <p
            style={{
              fontSize: '12px',
              lineHeight: '18px',
              marginBottom: '0',
              color: 'rgb(11, 38, 65)',
              fontWeight: 'normal',
            }}
          >
            <Trans
              i18nKey={'portal.footer.footer_disclaimer'}
              values={{
                waterHeadedWithGas: `${waterHeadedWithGas}`,
                waterHeadedWithCoal: `${waterHeadedWithCoal}`,
                waterHeadedWithElectric: `${waterHeadedWithElectric}`,
                waterHeadedWithOil: `${waterHeadedWithOil}`,
                waterHeadedWithRenewable: `${waterHeadedWithRenewable}`,
              }}
              components={{
                url: (
                  <a
                    style={{
                      textDecoration: 'underline',
                      fontSize: '12px',
                      lineHeight: '18px',
                      marginBottom: '0',
                      color: 'rgb(11, 38, 65)',
                    }}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                ),
              }}
            />
          </p>
        </>
      </Col>
    </Row>
  );
};

export default FooterInfo;
