import { createReducer } from '@reduxjs/toolkit';
import i18next from 'i18next';

const initialState = {
  filterOptions: {},
};

const filterReducer = createReducer(initialState, {
  SET_FILTER: (state, action) => {
    if (!state.filterOptions[action.name]) {
      return;
    }
    return {
      ...state,
      filterOptions: {
        ...state.filterOptions,
        [action.name]: {
          options: state.filterOptions[action.name].options,
          filterBy: action.filterBy,
        },
      },
    };
  },

  SET_FILTER_OPTIONS: (state, action) => {
    const addEnergy = { value: 'energy', label: i18next.t('portal.filter.energy.type.option') };

    //special case to add energy key to filters
    if (action.name === 'Filtereasywinsbytype') {
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          [action.name]: {
            options: { ...action.options, addEnergy },
            filterBy: '',
          },
        },
      };
    }

    return {
      ...state,
      filterOptions: {
        ...state.filterOptions,
        [action.name]: {
          options: { ...action.options },
          filterBy: '',
        },
      },
    };
  },
});

export default filterReducer;
