import React from 'react';
import * as _ from 'lodash';
import { ExternalLinksReducer } from '../../interfaces';
import { Col, Row } from 'react-styled-flexboxgrid';
import { Competitor } from '@savewatersavemoney/swsm-ui';
import Typography from '../Typography';
import { useTranslation } from 'react-i18next';

interface ExternalLinksProps {
  externalLinks: ExternalLinksReducer;
  appWidth: number;
}

const ExternalLinks: React.FC<ExternalLinksProps> = props => {
  const { appWidth } = props;
  const isDesktop = appWidth < 800;
  const [t] = useTranslation('common');

  const getUsefulLinks = () => {
    if (props.externalLinks.data && props.externalLinks.data[0].length > 0) {
      const title = t('portal.section.1.text');
      return (
        <Row style={{ marginBottom: isDesktop ? '64px' : '24px' }}>
          <Col xs={12} sm={12} md={12}>
            <Typography size="3" weight={600} text={title} />{' '}
          </Col>
          {props.externalLinks.data[0] &&
            _.map(props.externalLinks.data[0].components, (el, index) => {
              return (
                <Col xs={12} sm={12} md={6} key={index}>
                  <Competitor
                    title={t(el.text)}
                    id={3}
                    icon={el.icon}
                    isDesktop={isDesktop}
                    type="LINK"
                    linkUrl={el.url}
                    onSupportClick={() => void 0}
                  />
                </Col>
              );
            })}
        </Row>
      );
    }
    return null;
  };

  return getUsefulLinks();
};

export default ExternalLinks;
