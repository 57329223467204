import styled from 'styled-components';

const Wraper = styled.div`
  position: relative;
`;

const PercentText = styled.div`
  font-size: 40px;
  color: #25b3eb;
  margin: 10px auto 0;
`;

export default { Wraper, PercentText };
