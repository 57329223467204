import React from 'react';
import { InputControlProps } from '../../../interfaces';
import Styled from './InputControl.style';
import { isUndefined } from 'util';
import Input from '../../FromInput';
import { Icon } from '@savewatersavemoney/swsm-ui';

const InputControl: React.FC<InputControlProps> = props => {
  const {
    answerKey,
    min,
    max,
    value,
    inputType,
    required,
    placeholder,
    setNavNextVisibility,
    validateRequiredKeys,
    updateAnswerKey = () => void 0,
  } = props;

  const isNumeric = () => {
    if (inputType === 'number' || inputType === 'currency') {
      return true;
    }
    return false;
  };
  const currentValue = value != null ? value : undefined;

  const handleUpdate = value => {
    if (value === '') {
      updateAnswerKey(answerKey, undefined);
    }
    else if (isNumeric()) {
      if (value >= min && value <= max) {
        updateAnswerKey(answerKey, parseInt(value, 10));
        setNavNextVisibility(true);
      }
    }
    else {
      updateAnswerKey(answerKey, value);
      setNavNextVisibility(true);
    }

    if (required) {
      validateRequiredKeys();
    }
  };

  return (
    <Styled.Wraper>
      <Styled.Control>
        {inputType === 'currency' ? (
          <div style={{ position: 'absolute', left: '5px', top: '5px' }}>
            <Icon icon={'pound'} size={40} />
          </div>
        ) : null}

        <Input
          id={`GWF-${answerKey}`}
          style={{ marginLeft: '50px', width: 'calc(100% - 86px)' }}
          min={min}
          max={max}
          value={isUndefined(currentValue) ? '' : currentValue}
          aria-label={answerKey}
          placeholder={placeholder}
          onChange={event => {
            handleUpdate(event.target.value);
          }}
          type={inputType === 'currency' ? 'number' : inputType}
        />
      </Styled.Control>
    </Styled.Wraper>
  );
};

export default InputControl;
