import React from 'react';
import * as _ from 'lodash';
import { Bar, BarChart, ResponsiveContainer, ReferenceLine, Cell, LabelList } from 'recharts';
import { themeToColor, CustomBarNegativeLegend, prettifyIfNumber } from '../../../helpers';
import { ChartData } from '../../../interfaces';
import { useTranslation } from 'react-i18next';

import Styled from './ChartBarNegative.style';

interface ChartBarNegativeProps {
  id: number;
  chartData: ChartData;
  title: string;
  theme?: string;
  displayMoney?: string;
}

const ChartBarNegative: React.FC<ChartBarNegativeProps> = props => {
  const { chartData, displayMoney, theme = 'c1' } = props;
  const transformedData = chartData.dataSets.use?.data;
  const transformedDataCost = chartData.dataSets.cost?.data;
  const { chartTheme } = chartData;
  // const xLabels = displayMoney === 'cost' ? chartData.dataSets.cost?.xLabel : chartData.dataSets.use?.xLabel;
  const barColors = chartTheme ? themeToColor(chartTheme.split(',')) : themeToColor(theme.split(','));
  const [t] = useTranslation('common');

  const data = displayMoney === 'cost' ? transformedDataCost : transformedData;
  const renderCustomizedLabel = props => {
    const { x, y, height, width, value } = props;
    const radius = 20;
    return (
      <text
        x={x + width / 2}
        y={value < 0 ? y + 1.55 * radius : y - radius}
        width={width}
        height={height}
        fill="#0F3E5D"
        style={{ fontWeight: 700 }}
        textAnchor="middle"
      >
        <tspan x={x + width / 2} dy="0em">
          {prettifyIfNumber(value)}
        </tspan>
      </text>
    );
  };
  return (
    <div>
      <Styled.Wrap>
        <div
          style={{
            width: '100%',
            height: '40px',
            backgroundColor: '#E7EAEC',
            borderLeft: '5px solid black',
            fontWeight: 700,
            color: 'black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            flexDirection: 'column',
            fontFamily: 'Open Sans',
          }}
        >
          <p
            style={{
              width: '230px',
              fontFamily: 'Open Sans',
              marginBottom: '0px',
              marginTop: '0px',
              textAlign: 'center',
            }}
          >
            {t('portal.my_use.chartbar_above_average')}
          </p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <div
            style={{
              width: '5px',
              height: '440px',
              backgroundColor: 'black',
              borderLeft: '5px solid black',
              fontWeight: 700,
              color: 'black',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              flexDirection: 'column',
            }}
          ></div>

          <ResponsiveContainer height={440} width="100%">
            <BarChart
              data={data}
              height={400}
              margin={{
                top: 100,
                right: 0,
                bottom: 100,
                left: 0,
              }}
              barCategoryGap={30}
            >
              <ReferenceLine y={0} ifOverflow="extendDomain" stroke="#000" strokeWidth={30} />

              <Bar
                name="value"
                maxBarSize={80}
                barSize={80}
                dataKey="value"
                fill={_.get(barColors, theme)}
                radius={[4, 4, 0, 0]}
                minPointSize={2}
              >
                <LabelList dataKey="value" content={renderCustomizedLabel} position="top" />
                {data?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={_.get(barColors, index, theme)}
                    className={entry.value < 0 ? 'bar-negative' : 'bar-positive'}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div
          style={{
            width: '100%',
            height: '40px',
            backgroundColor: '#E7EAEC',
            borderLeft: '5px solid black',
            fontWeight: 700,
            color: 'black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            flexDirection: 'column',
            fontFamily: 'Open Sans',
          }}
        >
          <p
            style={{
              width: '230px',
              fontFamily: 'Open Sans',
              marginBottom: '0px',
              marginTop: '0px',
              textAlign: 'center',
            }}
          >
            {t('portal.my_use.chartbar_below_average')}
          </p>
        </div>
        {CustomBarNegativeLegend({ data, color: barColors })}
      </Styled.Wrap>
    </div>
  );
};

export default ChartBarNegative;
