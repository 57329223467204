// import * as _ from 'lodash';
import { createReducer } from '@reduxjs/toolkit';
import { TranslationReducer } from '../interfaces';
import * as _ from 'lodash';

const initialState: TranslationReducer = {
  status: {
    loading: false,
    error: false,
    done: false,
  },
  availableLanguages: null,
  currentLanguage: null,
};

const translationReducer = createReducer(initialState, {
  GET_TRANSLATION_OPTIONS_PENDING: (state, action) => {
    state.status = {
      loading: true,
      error: false,
      done: false,
    };

    state.availableLanguages = null;
    state.currentLanguage = null;
  },

  GET_TRANSLATION_OPTIONS_FULFILLED: (state, action) => {
    state.status = {
      loading: false,
      error: false,
      done: true,
    };

    state.availableLanguages = _.get(action.payload.data.data, 'site.availableLangs');
    state.currentLanguage = _.get(action.payload.data.data, 'site.lang');
  },

  GET_TRANSLATION_OPTIONS_REJECTED: (state, action) => {
    state.status = {
      loading: false,
      error: true,
      done: true,
    };

    state.availableLanguages = null;
    state.currentLanguage = null;
  },
});

export default translationReducer;
