import axios from 'axios';
import { getMicroServiceUrl, getV7MicroServiceUrl } from '../helpers';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import { getPage } from '../actions';
import { updateChallenge } from './challenges';

export const addToOrder = id => ({
  type: 'ADD_TO_ORDER',
  id,
});

export const resetRewardProgres = () => {
  return dispatch => {
    const response = dispatch({
      type: 'RESET_REWARD_PROGRES',
      payload: axios.get(`${getMicroServiceUrl('gamification')}reset`),
    });

    response.then(res => {
      console.log(res.value);
      window.location.reload();
    });
  };
};

export const resetOrder = () => ({
  type: 'RESET',
});

export const removeFromOrder = id => ({
  type: 'REMOVE_FROM_ORDER',
  id,
});

export const getChallenges = () => ({
  type: 'GET_CHALLENGES',
  payload: axios.get(`${getMicroServiceUrl('gamification', '2.0')}challenges`),
});

export const getGeneralData = () => ({
  type: 'GET_GENERAL_DATA',
  payload: axios.get(`${getMicroServiceUrl('gamification', '2.0')}general`),
});

export const getBonusChallenges = () => ({
  type: 'GET_BONUS_CHALLENGES',
  payload: axios.get(`${getMicroServiceUrl('gamification', '2.0')}challenges/category/bonus`),
});

export const socialShare = (tag, social) => {
  return dispatch => {
    const response = dispatch({
      type: 'SOCIAL_SHARE',
      payload: axios.post(`${getMicroServiceUrl('gamification')}share`, {
        tag,
        social,
      }),
    });

    response
      .then(() => {
        dispatch(getGeneralData());
      })
      .catch(err => {
        console.log(err);
      });
  };
};

export const submitOrder = (data, onSuccess, onFailed) => {
  return dispatch => {
    const response = dispatch({
      type: 'SUBMIT_ORDER',
      payload: axios.post(`${getV7MicroServiceUrl('freepack')}checkout`, data),
    });

    response
      .then(resp => {
        console.log(resp);
        dispatch(getGeneralData());
        onSuccess();
        window._paq.push(['trackEvent', 'freepack', 'freepack ordered']);
      })
      .catch(err => {
        console.log(err);
        onFailed();
      });
  };
};
//

export const getPreferences = () => ({
  type: 'GET_PREFERENCES',
  payload: axios.get(`${getMicroServiceUrl('user')}user`),
});

export const getDiscountLink = data => ({
  type: 'GET_DISCOUNT_LINK',
  payload: axios.get(`${getMicroServiceUrl('gamification', '2.0')}twss/discountLink`, data),
});

export const getInfo = data => ({
  type: 'GET_INFO',
  payload: axios.get(`${getV7MicroServiceUrl('portal')}getinfo`, data),
});

export const getMyUse = data => ({
  type: 'GET_MY_USE',
  payload: axios.get(`${getV7MicroServiceUrl('data')}myuse`, data),
});

export const getFreeProducts = slug => {
  return {
    type: 'GET_FREE_PRODUCTS',
    payload: axios.get(`${getV7MicroServiceUrl('freepack')}client/${slug}/products`),
  };
};

export const preCheckout = data => ({
  type: 'PRE_CHECKOUT',
  payload: axios.post(`${getV7MicroServiceUrl('freepack')}pre-checkout`, data),
});

export const getExternalLinks = data => ({
  type: 'GET_EXTERNAL_LINKS',
  payload: axios.get(`${getMicroServiceUrl('portal')}externalLinks`, data),
});

export const getProductsFitted = data => ({
  type: 'GET_PRODUCTS_FITTED',
  payload: axios.get(`${getV7MicroServiceUrl('data')}get_ordered_product_details`, data),
});

export const setProductsFitted = (data, onSuccess, onFailed) => {
  return dispatch => {
    const response = dispatch({
      type: 'SET_PRODUCTS_FITTED',
      payload: axios.post(`${getV7MicroServiceUrl('data')}save_product_feedback`, data),
    });
    response
      .then(() => {
        dispatch(getGeneralData());
        onSuccess();
      })
      .catch(err => {
        onFailed();
      });
  };
};

export const setCustomerService = (data, onSuccess, onFailed) => {
  const email = 'getwaterfit1@savewatersavemoney.com';
  const token = 'dZwEvsRblz2DpBQGfQL2DEzvcr57K6dJkmuDItWw';
  const auth = email + '/token:' + token;

  const ticket = JSON.stringify({
    request: {
      subject: data.subject,
      comment: {
        body: `message: ${data.freeText}`,
      },
      custom_fields: [
        { id: 1900005085913, value: data.name },
        { id: 1900005085933, value: data.phone },
        { id: 360029604918, value: data.email },
        { id: 1900005188153, value: data.query },
        { id: 360029815997, value: data.query2 },
        { id: 1900005084813, value: data.waterCompany },
        { id: 360029604898, value: data.orderNumber },
        { id: 1900005086093, value: data.freeText },
      ],
    },
  });

  return dispatch => {
    const response = dispatch({
      type: 'SET_CUSTOMER_SERVICE',
      payload: axios.post('https://savewatersavemoney.zendesk.com/api/v2/requests.json', ticket, {
        headers: {
          Authorization: 'Basic ' + btoa(auth),
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }),
    });
    response
      .then(data => {
        onSuccess();
      })
      .catch(err => {
        onFailed();
        toast.error(_.get(err, 'data.errors.msg', 'Something has gone wrong. Please try again later'), {
          autoClose: 5000,
        });
      });
  };
};

export const sendContactUs = (data, onSuccess, onFailed) => {
  const ticket = JSON.stringify({
    recaptchaResponse: data.recaptchaResponse,
    zendeskRequest: {
      request: {
        subject: data.subject,
        comment: {
          body: `message: ${data.freeText}`,
        },
        custom_fields: [
          { id: 1900005085913, value: data.name },
          { id: 1900005085933, value: data.phone },
          { id: 360029604918, value: data.email },
          { id: 1900005188153, value: data.query },
          { id: 360029815997, value: data.query2 },
          { id: 1900005084813, value: data.waterCompany },
          { id: 360029604898, value: data.orderNumber },
          { id: 1900005086093, value: data.freeText },
        ],
      },
    },
  });

  return dispatch => {
    const response = dispatch({
      type: 'SET_CONTACT_FORM',
      payload: axios.post(`${getV7MicroServiceUrl('user')}contact`, ticket, {
        headers: {
          Authorization: 'Basic',
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }),
    });
    response
      .then(() => {
        onSuccess();
      })
      .catch(err => {
        onFailed();
        toast.error(_.get(err, 'data.errors.msg', 'Something has gone wrong. Please try again later'), {
          autoClose: 5000,
        });
      });
  };
};

export const clearAddress = () => ({
  type: 'CLEAR_ADDRESS',
});

export const persistDetails = details => ({
  type: 'PERSIST_DETAILS',
  details,
});

export const getAddresses = postcode => ({
  type: 'GET_ADDRESSES',
  payload: axios.get(`${getV7MicroServiceUrl('freepack')}address/${postcode}`),
});

export const getNotifications = () => ({
  type: 'GET_NOTIFICATIONS',
  payload: axios.get(`${getMicroServiceUrl('user')}mailbox?unreadOnly=true`),
});

export const readNotification = id => ({
  type: 'READ_NOTIFICATIONS',
  payload: axios.put(`${getMicroServiceUrl('user')}mailbox/markRead/${id}`),
});

export const setPreferences = (data, cb) => {
  return dispatch => {
    const response = dispatch({
      type: 'SET_PREFERENCES',
      payload: axios.post(`${getMicroServiceUrl('user')}preferences`, data),
    });
    response
      .then(() => {
        cb();
      })
      .catch(err => {
        cb();
      });
  };
};

export const donatePoints = id => {
  return dispatch => {
    const response = dispatch({
      type: 'DONATE_POINTS',
      payload: axios.post(`${getMicroServiceUrl('gamification')}leaderboard/donate`, {
        charity: id,
      }),
    });
    response
      .then(res => {
        dispatch(getPage('leaderboard', 'gamification'));
      })
      .catch(err => {});
  };
};

export const donateLitres = team_id => {
  return dispatch => {
    const response = dispatch({
      type: 'DONATE_LITRES',
      payload: axios.post(`${getMicroServiceUrl('gamification')}competitions/join`, {
        team_id,
      }),
      meta: {
        team_id,
      },
    });
    response
      .then(res => {
        toast.success(_.get(res, 'message', 'You have successfully allocated your litre savings to your team'), {
          autoClose: 5000,
        });
      })
      .catch(err => {
        toast.error(_.get(err, 'data.errors', 'Something has gone wrong'), {
          autoClose: 5000,
        });
      });
  };
};

export const completeChallenge = id => {
  return dispatch => {
    const response = dispatch({
      type: 'COMPELTE_CHALLENGE',
      payload: axios.post(`${getMicroServiceUrl('gamification', '2.0')}challenges/${id}/complete`),
      meta: {
        id,
      },
    });
    response
      .then(data => {
        setTimeout(() => {
          dispatch(updateChallenge(data));
          dispatch(getGeneralData());
        }, 1500);
      })
      .catch(err => {
        toast.error(_.get(err, 'data.errors', 'Something has gone wrong'), {
          autoClose: 5000,
        });
      });
  };
};

export const startChallenge = id => {
  return dispatch => {
    const response = dispatch({
      type: 'START_CHALLENGE',
      payload: axios.post(`${getMicroServiceUrl('gamification', '2.0')}challenges/${id}/start`),
      meta: {
        id,
      },
    });
    response
      .then(data => {
        dispatch(updateChallenge(data));
        dispatch(getGeneralData());
      })
      .catch(err => {
        toast.error(_.get(err, 'data.errors', 'Something has gone wrong'), {
          autoClose: 5000,
        });
      });
  };
};

export const tickPledge = id => {
  return dispatch => {
    const response = dispatch({
      type: 'START_PLEDGE',
      payload: axios.post(`${getMicroServiceUrl('gamification', '2.0')}challenges/${id}/tickPledge`),
    });
    response
      .then(data => {
        setTimeout(() => {
          dispatch(updateChallenge(data));
          dispatch(getGeneralData());
        }, 1500);
      })
      .catch(err => {
        console.log(err);
        toast.error(_.get(err, 'data.errors', 'Something has gone wrong'), {
          autoClose: 5000,
        });
      });
  };
};

export const postLeakRepairSignup = (data, taskTag, taskId, onSuccess) => {
  const userData = {
    taskTag: `${taskTag}`,
    taskId,
    data: {
      ...data,
    },
  };

  return dispatch => {
    const response = dispatch({
      type: 'POST_TASK_DATA',
      payload: axios.post(`${getMicroServiceUrl('user-content')}task-data`, userData),
    });

    response
      .then(() => {
        dispatch(completeChallenge(data.id));
        dispatch({ type: 'LEAK_FIX_SIGNUP' });
        dispatch(getGeneralData());
      })
      .catch(err => {
        toast.error(_.get(err, 'data.errors', 'Something has gone wrong'), {
          autoClose: 5000,
        });
      });
  };
};

export const getActionBanner = () => ({
  type: 'GET_ACTION_BANNER',
  payload: axios.get(`${getMicroServiceUrl('portal')}banner`),
});

export const dismissBanner = id => ({
  type: 'SET_DISMISS_BANNER',
  payload: axios.post(`${getMicroServiceUrl('portal')}banner/dismiss/${id}`),
});

export const getVideoLibrary = () => ({
  type: 'GET_VIDEO_LIBRARY',
  payload: axios.get(`${getMicroServiceUrl('portal')}getvideo`),
});

export const deleteAccount = () => ({
  type: 'DELETE_ACCOUNT',
  payload: axios.delete(`${getMicroServiceUrl('user')}user/delete`),
});

export const redeemPromo = (code, onSuccess, onFailed) => {
  return dispatch => {
    const response = dispatch({
      type: 'REDEEM_PROMO',
      payload: axios.put(`${getMicroServiceUrl('user')}campaign/registerUtmCode/${code}`),
    });
    response
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onFailed();
      });
  };
};
