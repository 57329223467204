import { connect } from 'react-redux';
import SavingsEngine from './SavingsEngine';
import { actionsSavingEngine } from '../../actions/qaIndex';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state, ownProps) => {
  return {
    savingengineReducer: state.savingengineReducer,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getQuestions: () => dispatch(actionsSavingEngine.getQuestions(ownProps.waterClient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SavingsEngine);
