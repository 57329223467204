import { connect } from 'react-redux';
import Chapter from './Chapter';
import { actionsSavingEngine } from '../../../actions/qaIndex';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state, ownProps) => {
  return {
    data: state.savingengineReducer.steps[ownProps.currentStep],
    answers: state.savingengineReducer.answers,
    currentStep: ownProps.currentStep,
    app: state.appReducer,
    appWidth: state.uiReducer.appWidth,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    goToPrev: () => dispatch(actionsSavingEngine.goToPrev()),
    goToNext: () => dispatch(actionsSavingEngine.goToNext()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chapter);
