import Loadable from 'react-loadable';
import Loading from '../../../components/Loader';

// CONDITIONAL PAGES
const Login = Loadable({
  loader: () => import('./LoginContainer'),
  loading: Loading,
});

export default Login;
