import styled from 'styled-components';

const Wraper = styled.div`
  flex: 0 0 100%;
`;

const Control = styled.div`
  position: relative;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export default { Wraper, Control };
