import {
  PageOverview,
  GenericMarkdown,
  PageLeaderboard,
  PageChallenge,
  PageCompetitions,
  PagePreferences,
  PageConsumption,
  FreeProducts,
  PageCustomerService,
  Checkout,
  Thankyou,
  PageVideoLibrary,
  ProductsFitted,
  Rewards,
} from './components/Pages/lazy';
import { Routes } from './interfaces';

const routes: Routes = {
  '/portal': {
    template: PageOverview,
    authRequired: true,
  },

  '/myuse': {
    template: PageConsumption,
    authRequired: true,
  },
  '/free-products': {
    template: FreeProducts,
    authRequired: true,
  },
  '/free-products/checkout': {
    template: Checkout,
    authRequired: true,
  },
  '/free-products/thankyou': {
    template: Thankyou,
    authRequired: true,
  },
  '/saving/products-fitted': {
    template: ProductsFitted,
    authRequired: true,
  },
  '/donatecoins': {
    template: PageLeaderboard,
    authRequired: true,
  },
  '/competitions': {
    template: PageCompetitions,
    authRequired: true,
  },
  '/video-library': {
    template: PageVideoLibrary,
    authRequired: true,
  },
  '/profile': {
    template: PagePreferences,
    authRequired: true,
  },
  '/easywins': {
    template: PageChallenge,
    authRequired: true,
  },
  '/customer-service': {
    template: PageCustomerService,
    authRequired: true,
  },
  '/rewards': {
    template: Rewards,
    authRequired: true,
  },

  '/faq': {
    template: GenericMarkdown,
  },
  '/contact-us': {
    template: GenericMarkdown,
  },
  '/terms-and-conditions': {
    template: GenericMarkdown,
  },
  '/privacy': {
    template: GenericMarkdown,
  },
  '/cookies': {
    template: GenericMarkdown,
  },
  '/other-policies': {
    template: GenericMarkdown,
  },
  '/end-user-license-agreement': {
    template: GenericMarkdown,
  },
  '/moderation-policy': {
    template: GenericMarkdown,
  },
};

export default routes;
