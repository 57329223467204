import styled, { css } from 'styled-components';
import { measurements, colors } from './variables';

function toPixels(value: number): string {
  return `${value}px`;
}

const Row = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  margin-right: -${toPixels(measurements.gutter / 2)};
  margin-left: -${toPixels(measurements.gutter / 2)};
`;

const Container = styled.div`
  padding-left: ${toPixels(measurements.gutter / 2)};
  padding-right: ${toPixels(measurements.gutter / 2)};
  margin: 0 auto;
  max-width: 1200px;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  ${props => {
    const { width = '100%' } = props;
    return css`
      flex: 0 1 ${width}};
      max-width: ${width}};
    `;
  }};

  padding-left: ${measurements.gutter / 2}px;
  padding-right: ${measurements.gutter / 2}px;
  margin-bottom: 40px;
`;
const Box = styled.div`
  position: relative;
  height: 100%;
  border-radius: 10px;
  border: 4px solid #d7f1ff;
  padding: 32px;
  max-width: 660px;
  width: 100%;
  margin: auto;
  a {
    text-decoration: underline;
    color: ${colors.primary};
    text-decoration-thickness: 2px;
    text-decoration-color: #25b3eb;
    text-underline-offset: 5px;
  }
`;

export const IconWrap = styled.div`
  position: absolute;
  right: 15px;
  top: 33px;
  .eye {
    transition: transform 0.2s;
    &:hover {
      transform: scale(1.2);
    }
  }
`;

const Input = styled.input`
  -webkit-appearance: none;
  display: block;
  font-size: 0.9rem;
  color: ${colors.baseDark};
  padding: 0.7em;
  border-width: 0 0 1px 0;
  border-color: ${colors.disabled};
  width: 100%;
  background-color: transparent;
  font-weight: 400;
  line-height: inherit;
`;
const FormGroup = styled.div`
  position: relative;
  display: block;
  margin-bottom: 16px;
`;
const Label = styled.div`
  color: ${colors.primary};
  display: block;
  font-weight: 600;
  label {
    margin-left: 5px;
  }
`;
const AlignCenter = styled.div`
  text-align: center;
`;

const Line = styled.hr`
  clear: both;
  max-width: 75rem;
  height: 0;
  width: 100%;
  margin: 0 auto 20px;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #25b3eb;
  border-left: 0;
`;

export const Error = styled.span`
  color: red;
`;

const clearfix = css`
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
`;

export const swsmTransition = (property, time = '200ms', easing = 'cubic-bezier(0.45, 0.22, 0.41, 0.92)') => {
  return css`
    transition: ${property} ${time} ${easing};
  `;
};

const swsmDepth = depth => {
  if (depth) {
    return css`
      box-shadow: 0 ${depth}px ${depth * 2}px 0 rgba(0, 0, 0, 0.2);
    `;
  }
  return css`
    box-shadow: none;
  `;
};

export default {
  clearfix,
  swsmTransition,
  swsmDepth,
  Row,
  Col,
  Container,
  IconWrap,
  Box,
  Input,
  FormGroup,
  Label,
  AlignCenter,
  Line,
  Error,
};
