import { connect } from 'react-redux';
import Progress from './Progress';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = state => {
  return {
    steps: state.savingengineReducer.steps,
    answers: state.savingengineReducer.answers,
    currentStep: state.savingengineReducer.currentStep,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Progress);
