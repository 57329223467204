import React from 'react';
import Question from '../Pages/Question';
import Chapter from '../Pages/Chapter';
import Summary from '../Pages/Summary';
import { TransitionMotion, spring } from 'react-motion';
import { Loader, Button } from '@savewatersavemoney/swsm-ui';
import Styled from './SavingsEngine.style';
import { getVariable } from '../../helpers';

interface SavingEngineProps {
  getQuestions: Function;
  getQuestionsUtm: Function;
  savingengineReducer: any;
  waterClient: string;
}

class SavingEngine extends React.Component<SavingEngineProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      questionHeight: 0,
    };
  }

  setQuestionHeight = height => {
    this.setState({ questionHeight: height });
  };

  componentDidMount() {
    const { waterClient, getQuestions } = this.props;

    getQuestions(waterClient);
  }

  renderStep = (type, currentStep) => {
    const { setQuestionHeight } = this;

    switch (type) {
      case 'question':
        return <Question currentStep={currentStep} setQuestionHeight={setQuestionHeight} />;
      case 'chapter':
        return <Chapter currentStep={currentStep} setQuestionHeight={setQuestionHeight} />;
      case 'summary':
        return <Summary qualified currentStep={currentStep} setQuestionHeight={setQuestionHeight} />;
      case 'summary_not_qualified':
        return <Summary qualified={false} currentStep={currentStep} setQuestionHeight={setQuestionHeight} />;
      default:
        return (
          <div>
            Step Type: <span style={{ color: 'red' }}>'{type}'</span> not exist
          </div>
        );
    }
  };

  render() {
    const { savingengineReducer, getQuestions, waterClient } = this.props;
    const { fetched, error, steps, currentStep, animateForward } = savingengineReducer;
    const { questionHeight } = this.state;
    const { renderStep } = this;

    if (error) {
      return (
        <Styled.LoaderWrap>
          <p>Something is wrong...</p>
          <Button
            onClick={() => {
              getQuestions(waterClient);
            }}
          >
            Try Again
          </Button>

          <br />
          <Button
            style={{
              marginTop: '20px',
            }}
            onClick={() => {
              window.location.href = window.location.origin;
            }}
          >
            Go back
          </Button>
        </Styled.LoaderWrap>
      );
    }
    else if (!fetched) {
      return (
        <Styled.LoaderWrap>
          <Loader inline size={2} />
          <div>Loading Questions...</div>
        </Styled.LoaderWrap>
      );
    }
    else if (steps.length < 1) {
      // window.location.replace(document.location.origin);
      return <div className="spinner__wrap">No questions in response</div>;
    }

    const defaultSpring = { stiffness: 550, damping: 40 };
    const animateDistance = 400;

    const containerStyle = {
      height: questionHeight,
    };

    const willEnter = () => ({
      opacity: 0,
      translate: animateForward ? animateDistance : -animateDistance,
    });
    const willLeave = () => ({
      opacity: spring(0, defaultSpring),
      translate: spring(animateForward ? -animateDistance : animateDistance, defaultSpring),
    });
    const getStyles = () => ({
      opacity: spring(1, defaultSpring),
      translate: spring(0, defaultSpring),
    });

    const swsmInternal = getVariable('mode', 'swsm', false);

    return (
      <Styled.StepWrap className="step-wrap" swsmInternal={swsmInternal}>
        <Styled.TransitionWrap style={containerStyle}>
          <TransitionMotion
            styles={[
              {
                key: 'key-' + currentStep,
                style: getStyles(),
                data: {
                  containerStyle,
                  currentStep,
                  stepType: steps[currentStep].type,
                },
              },
            ]}
            willEnter={willEnter}
            willLeave={willLeave}
          >
            {interpolated => (
              <div>
                {interpolated.map(({ key, style, data }) => (
                  <Styled.TransitionElement
                    key={`id-${key}`}
                    className="GWF-app"
                    style={{
                      opacity: style.opacity,
                      transform: `translate(${style.translate}px)`,
                    }}
                  >
                    {renderStep(data.stepType, data.currentStep)}
                  </Styled.TransitionElement>
                ))}
              </div>
            )}
          </TransitionMotion>
        </Styled.TransitionWrap>
      </Styled.StepWrap>
    );
  }
}

export default SavingEngine;
