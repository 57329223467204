import { UnsubscribeReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

const initialState: UnsubscribeReducer = {
  status: {
    loading: false,
    error: false,
    done: false,
  },
};

const reviewReducer = createReducer(initialState, {
  UNSUBSCRIBE_PENDING: state => {
    state.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  UNSUBSCRIBE_FULFILLED: state => {
    state.status = {
      loading: false,
      error: false,
      done: true,
    };
  },

  UNSUBSCRIBE_REJECTED: state => {
    state.status = {
      loading: false,
      error: true,
      done: false,
    };
  },
});

export default reviewReducer;
