import { clearFix } from 'polished';
import StyledTypography from '../../Typography/Typography.style';
import styled from 'styled-components';

const Question = styled(StyledTypography.QAHeadTwo)``;

const Wraper = styled.div`
  text-align: center;
  position: absolute;
  width: 100%;
  opacity: 1;
`;

const Container = styled.div`
  max-width: 600px;
  margin: auto;
  padding: 0 10px;
  ${clearFix()}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  flex-flow: row wrap;
  flex-direction: column;
  @media screen and (max-width: 415px) {
    max-width: 360px;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  color: #0b2641;
  font-weight: 600;
  margin: 0 0 20px 0;
  flex: 1 100%;
`;

const Icon = styled.div`
  flex: 1 100%;

  @media screen and (max-width: 415px) {
    margin: 8px 0;
  }

  @media screen and (min-width: 415px) {
    margin: 16px 0;
  }
`;

// const Question = styled.h2`
//   letter-spacing: 0.9px;
//   color: #656565;
//   font-size: 16px;
//   max-width: 400px;
//   margin: 0 auto 20px auto;
//   line-height: 1.4;
//   white-space: pre-line;
//   flex: 1 100%;
//   font-weight: 400;
// `;

export default { Wraper, Container, Title, Question, Icon };
