import { PromoCodeReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
const initialState: PromoCodeReducer = {
  status: {
    loading: false,
    error: false,
    done: false,
  },
  data: null,
};
const promoCodeReducer = createReducer(initialState, {
  REDEEM_PROMO_PENDING: state => {
    state.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  REDEEM_PROMO_FULFILLED: (state, action) => {
    state.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.data = action.payload.data;
  },
  REDEEM_PROMO_REJECTED: (state, action) => {
    state.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.data = action.payload.data;
  },
});
export default promoCodeReducer;
