import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Col } from 'react-styled-flexboxgrid';
import { Error } from './ResetPassword.style';
import Styled from '../../../assets/SharedStyles.style';
import { Button, ButtonClean, Input } from '@savewatersavemoney/swsm-ui';
import history from '../../../history';
import { colors } from '../../../assets/variables';
import { useTranslation } from 'react-i18next';
import Typography from '../../Typography';

interface ResetPasswordRequestProps {
  resetPasswordRequest: Function;
  resetPasswordRequestRepeat: Function;
  resetRequest: any;
}

const ResetPasswordRequest: React.FC<ResetPasswordRequestProps> = props => {
  const { resetPasswordRequest, resetPasswordRequestRepeat, resetRequest } = props;
  const [t] = useTranslation('common');
  const allDone = t('portal.forgot_password.all_done');
  const tryAgain = t('portal.forgot_password.try_again');
  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('portal.general_form_errors.email_not_valid'))
      .required(t('portal.general_form_errors.required')),
  });
  const pageTitle = t('portal.forgot_password.page.title');
  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        resetPasswordRequest(values.email);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <Typography size="1" text={pageTitle} />
          <Styled.FormGroup>
            <Col xs={12}>
              <Input
                label={t('portal.forgot_password.email_label')}
                type="email"
                name="email"
                id="email"
                placeholder="john.doe@gmail.com"
                onChange={handleChange}
                onBlur={handleBlur}
                icon="pen"
                aria-label={t('portal.forgot_password.email_label')}
                value={values.email}
                error={errors.email && touched.email ? errors.email : undefined}
              />
            </Col>
          </Styled.FormGroup>
          {resetRequest.status.error ? (
            <Styled.FormGroup>
              <Col xs={12}>
                <Error>{resetRequest.status.error}</Error>
              </Col>
            </Styled.FormGroup>
          ) : null}

          <Col xs={12}>
            {resetRequest.status.done ? (
              <Styled.AlignCenter>
                <p>
                  {allDone}{' '}
                  <ButtonClean
                    style={{ fontSize: '16px', color: colors.action }}
                    type="button"
                    onClick={() => {
                      resetPasswordRequestRepeat();
                    }}
                  >
                    {tryAgain}
                  </ButtonClean>
                </p>
              </Styled.AlignCenter>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                <Button disabled={isSubmitting || resetRequest.status.loading} type="submit">
                  {t('portal.forgot_password.reset_password_button')}
                </Button>
              </div>
            )}
          </Col>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
            <ButtonClean
              style={{
                color: '#0b2641',
                fontSize: '16px',
                textDecoration: 'underline',
                textDecorationThickness: '3px',
                textDecorationColor: '#25b3eb',
                textDecorationOffset: '5px',
              }}
              onClick={() => history.push('/login')}
            >
              {t('portal.forgot_password.take_me_back')}
            </ButtonClean>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default ResetPasswordRequest;
