import Loadable from 'react-loadable';
import Loading from '../../components/Loader';

// CONDITIONAL PAGES
export const Landing = Loadable({
  loader: () => import('../../components/Pages/Landing'),
  loading: Loading,
});

export const HindiLanding = Loadable({
  loader: () => import('../../components/Pages/HindiLanding'),
  loading: Loading,
});

export const CompanyPage = Loadable({
  loader: () => import('./Marketplace/CompanyPage'),
  loading: Loading,
});

export const Check = Loadable({
  loader: () => import('./Check'),
  loading: Loading,
});
export const PageVideoLibrary = Loadable({
  loader: () => import('../../components/Pages/PageVideoLibrary'),
  loading: Loading,
});

export const FreeProducts = Loadable({
  loader: () => import('../../components/Pages/FreeProducts'),
  loading: Loading,
});

export const Checkout = Loadable({
  loader: () => import('../../components/Pages/FreeProducts/Checkout/CheckoutContainer'),
  loading: Loading,
});

export const Thankyou = Loadable({
  loader: () => import('../../components/Pages/FreeProducts/Thankyou'),
  loading: Loading,
});

export const ProductsFitted = Loadable({
  loader: () => import('../../components/Pages/ProductsFitted'),
  loading: Loading,
});

export const PageOverview = Loadable({
  loader: () => import('../../components/Pages/PageOverview'),
  loading: Loading,
});

export const PageConsumption = Loadable({
  loader: () => import('../../components/Pages/PageConsumption'),
  loading: Loading,
});

export const PageLeaderboard = Loadable({
  loader: () => import('../../components/Pages/Leaderboard'),
  loading: Loading,
});

export const PageCompetitions = Loadable({
  loader: () => import('../../components/Pages/Competitions'),
  loading: Loading,
});

export const PageCustomerService = Loadable({
  loader: () => import('../../components/Pages/PageCustomerService'),
  loading: Loading,
});

export const PagePreferences = Loadable({
  loader: () => import('../../components/Pages/Preferences'),
  loading: Loading,
});
export const PageChallenge = Loadable({
  loader: () => import('../../components/Pages/PageChallenge'),
  loading: Loading,
});

export const Rewards = Loadable({
  loader: () => import('../../components/Pages/Rewards'),
  loading: Loading,
});

export const Marketplace = Loadable({
  loader: () => import('../../components/Pages/Marketplace'),
  loading: Loading,
});

export const GenericMarkdown = Loadable({
  loader: () => import('../../components/Pages/GenericMarkdown'),
  loading: Loading,
});

export const ResetPassword = Loadable({
  loader: () => import('../../components/Pages/ResetPassword'),
  loading: Loading,
});

export const Login = Loadable({
  loader: () => import('../../components/Pages/Login'),
  loading: Loading,
});

export const PageUnsubscribe = Loadable({
  loader: () => import('../../components/Pages/PageUnsubscribe'),
  loading: Loading,
});
