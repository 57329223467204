import styled from 'styled-components';
import { colors } from '../../assets/variables';

const Wrap = styled.div`
  background: white;
  margin-bottom: 16px;
  padding: 32px;
  height: 100%;
  border: 4px solid #d7f1ff;
  border-radius: 10px;
  width: 100%;
  margin-top: 32px;
`;

const Textarea = styled.textarea`
  font-family: 'Open Sans', sans-serif;
  border: 2px solid ${colors.primary};
  border-radius: 10px;
  padding: 8px;
`;
const ErrorInline = styled.div`
  font-size: 12px;
  padding: 5px 0 0;
  color: #ad0000;
`;

export default { Wrap, Textarea, ErrorInline };
