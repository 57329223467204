import React from 'react';
import Styled from './Typography.style';
import { Icon } from '@savewatersavemoney/swsm-ui';

interface TypographyProps {
  size: '1' | '2' | '3' | '4' | '5' | '6' | 'p' | 'icon' | 'sectionSeparator';
  text?: string | number;
  subText?: string;
  icon?: string;
  weight?: number;
  style?: any;
  textAlign?: string;
}
export class Typography extends React.Component<TypographyProps, any> {
  render() {
    const { size, text, icon, subText, weight, style, textAlign } = this.props;

    if (!text) {
      return null;
    }

    const renderTypography = size => {
      switch (size) {
        case '1':
          return (
            <Styled.HeadOne weight={weight} textAlign={textAlign} style={style}>
              {text}
            </Styled.HeadOne>
          );
        case '2':
          return (
            <Styled.HeadTwo weight={weight} textAlign={textAlign} style={style}>
              {text}
            </Styled.HeadTwo>
          );
        case '3':
          return (
            <Styled.HeadThree weight={weight} textAlign={textAlign} style={style}>
              {text}
            </Styled.HeadThree>
          );
        case '4':
          return (
            <Styled.HeadFour weight={weight} textAlign={textAlign} style={style}>
              {text}
            </Styled.HeadFour>
          );
        case '5':
          return (
            <Styled.HeadFive weight={weight} textAlign={textAlign} style={style}>
              {text}
            </Styled.HeadFive>
          );
        case '6':
          return (
            <Styled.HeadSix weight={weight} textAlign={textAlign} style={style}>
              {text}
            </Styled.HeadSix>
          );
        case 'p':
          return (
            <Styled.Paragraph weight={weight} textAlign={textAlign} style={style}>
              {text}
            </Styled.Paragraph>
          );
        case 'sectionSeparator':
          return <Styled.SectionSeparator style={style}>{text}</Styled.SectionSeparator>;
        case 'icon':
          return (
            <Styled.Icon>
              <Styled.IconWrap>{icon && <Icon icon={icon} size={60} />}</Styled.IconWrap>
              <Styled.TextWrap>
                <Styled.IconText>{text}</Styled.IconText>
                <Styled.IconSubText>{subText}</Styled.IconSubText>
              </Styled.TextWrap>
            </Styled.Icon>
          );
        default:
          return null;
      }
    };

    return renderTypography(size);
  }
}
export default Typography;
