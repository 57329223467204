import { VideoLibraryReducer } from '../interfaces';
import * as _ from 'lodash';
import { createReducer } from '@reduxjs/toolkit';
import i18next from 'i18next';

const initialState: VideoLibraryReducer = {
  status: {
    loading: false,
    error: false,
    done: false,
  },
  data: null,
};

const videoLibraryReducer = createReducer(initialState, {
  GET_VIDEO_LIBRARY_PENDING: state => {
    state.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  GET_VIDEO_LIBRARY_FULFILLED: (state, action) => {
    state.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.data = _.get(action, 'payload.data', i18next.t('portal.general.error_message'));
  },

  GET_VIDEO_LIBRARY_REJECTED: (state, action) => {
    state.status = {
      loading: false,
      error: _.get(action, 'payload.data.errors', i18next.t('portal.general.error_message')),
      done: false,
    };
  },
});

export default videoLibraryReducer;
