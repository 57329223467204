import React from 'react';
// import * as _ from 'lodash';

import Styled from './ProgressBar.style';

const ProgressBar = props => {
  const { steps, currentStep } = props;
  if (!steps || currentStep === 0)
    return null;
  const totalQuestions = () => {
    return steps.length;
  };
  const answeredQuestions = () => {
    return currentStep + 1;
  };
  const progress = Math.round((100 * answeredQuestions()) / totalQuestions());
  return (
    <Styled.Wraper>
      <Styled.Bar progress={progress}></Styled.Bar>
    </Styled.Wraper>
  );
};

export default ProgressBar;
