import Loadable from 'react-loadable';

const Footer = Loadable({
  loader: () => import('./FooterContainer'),
  loading() {
    return null;
  },
});

export default Footer;
