import React from 'react';

import Styled from './Progress.style';

const Progress = props => {
  const totalQuestions = () => {
    return props.steps.length;
  };

  const answeredQuestions = () => {
    return props.currentStep + 1;
  };

  const progress = Math.round((100 * answeredQuestions()) / totalQuestions());

  return (
    <div>
      <Styled.Wraper>
        {/* Hide chart on last step */}
        <div>You've completed...</div>
        <Styled.PercentText>{progress}%</Styled.PercentText>
      </Styled.Wraper>
    </div>
  );
};

export default Progress;
