import styled from 'styled-components';

const PieLegendWrap = styled.ul`
  padding-inline-start: 0;
  max-width: 400px;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  flex-basis: 100%;
  display: flex;
`;

const PieLegendLi = styled.li`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-basis: 33.33%;
`;

const PieLegendDot = styled.div`
  height: 24px;
  width: 24px;
  background-color: ${props => props.color};
  border-radius: 50%;
  margin-right: 10px;
`;

const PieLegendValue = styled.span`
  margin-left: auto;
  min-width: 70px;
  text-align: right;
`;

const BarToolTiplabel = styled.div`
  padding-bottom: 2px;
  font-size: 18px;
  color: #777;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 8px;
`;

const BarLegendWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 4px solid #d7f1ff;
  border-radius: 10px;
  margin-top: 24px;
  padding: 16px;
  @media screen and (max-width: 800px) {
    border: none;
  }
`;

const BarLegendLi = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1 0 100%;
  font-size: 14px;
  padding-bottom: 16px;
  &:last-child {
    padding-bottom: 0px;
  }
`;
const LabelName = styled.div`
  flex-grow: 1;
`;
const ValueNumber = styled.div`
  font-weight: 600;
  align-items: center;
  justify-content: flex-end;
`;
export default {
  BarLegendWrap,
  BarToolTiplabel,
  PieLegendWrap,
  PieLegendLi,
  LabelName,
  ValueNumber,
  BarLegendLi,
  PieLegendDot,
  PieLegendValue,
};
