import React from 'react';
import { Redirect, Route } from 'react-router-dom';

// Utils
import { isLoggedIn } from '../../helpers';

const PrivateRoute = ({ render: Component, ...rest }) => {
  const logged = isLoggedIn();

  return (
    <Route {...rest} render={props => (logged ? <Component {...props} /> : <Redirect to={{ pathname: '/login' }} />)} />
  );
};

export default PrivateRoute;
