import axios from 'axios';
import { getMicroServiceUrl } from '../helpers';

export const getChallengeData = () => ({
  type: 'GET_CHALLENGES',
  payload: axios.get(`${getMicroServiceUrl('gamification', '2.0')}challenges`),
});

export const getChallenge = id => {
  return dispatch => {
    const response = dispatch({
      type: 'GET_CHALLENGE',
      payload: axios.get(`${getMicroServiceUrl('gamification', '2.0')}challenges/${id}`),
    });

    response.then(res => {
      dispatch(updateFeaturedChallenge(res.value.data.challenge));
    });
  };
};

export const getChallengeList = () => {
  return dispatch => {
    dispatch({ type: 'GET_CHALLENGES' });
  };
};

export const updateChallenge = challenge => {
  return dispatch => {
    dispatch({ type: 'UPDATE_CHALLENGE', challenge });
    dispatch({ type: 'GET_CHALLENGES' });
  };
};

export const updateFeaturedChallenge = data => {
  return dispatch =>
    dispatch({
      type: 'GET_FEATURED_CHALLENGE_DATA',
      challenge: data,
    });
};
