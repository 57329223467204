import { connect } from 'react-redux';
import PageConsumption from './PageConsumption';
import { getMyUse } from '../../../actions/rewardProgram';
import { AppState } from '../../../interfaces';

const mapStateToProps = (state: AppState) => ({
  appWidth: state.uiReducer.appWidth,
  page: state.pageConsumptionReducer,
  app: state.appReducer,
});

const mapDispatchToProps = dispatch => ({
  getMyUse: endpoint => dispatch(getMyUse(endpoint)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageConsumption);
