import * as _ from 'lodash';

export const isConditionTrue = (answers, id, operator, value = 'false') => {
  const answerString = (answers[id] || false).toString();
  const answerNumber = parseInt(answers[id], 10);
  const andValueNumber = parseInt(value, 10);
  const andValueString = value.toString();

  switch (operator) {
    case '==':
      return answerString === andValueString;
    case '!=':
      return answerString !== andValueString;
    case '>':
      return answerNumber > andValueNumber;
    case '<':
      return answerNumber < andValueNumber;
    case '>=':
      return answerNumber >= andValueNumber;
    case '<=':
      return answerNumber <= andValueNumber;
    default:
      return false;
  }
};

const isConditionGroupTrue = (conditional_logic, answers) => {
  // console.log({ conditional_logic, answers });
  // console.log(conditional_logic.length);
  if (conditional_logic && conditional_logic.length > 0 && answers) {
    const orArray: Array<boolean> = [];
    _.forEach(conditional_logic, or_cond => {
      // console.log(or_cond);
      const andArray: Array<boolean> = [];
      _.forEach(or_cond, and_cond => {
        // console.log(and_cond);
        if (and_cond) {
          andArray.push(isConditionTrue(answers, and_cond.id, and_cond.operator, and_cond.value));
        }
      });
      orArray.push(_.every(andArray));
    });
    // console.log('conditional_logic RESULT: ' + orArray);
    const conditionPassed = _.some(orArray);
    return conditionPassed;
  }
};

const controlsHasConditionLogic = controls => {
  if (Array.isArray(controls) && controls.length > 0) {
    const hasCL = _.map(controls, el => {
      return el.hasOwnProperty('conditional_logic');
    });
    return _.some(hasCL);
  }
};

const cleanupAnswers = (answers, keysToReset) => {
  const newAnswers = { ...answers };
  keysToReset.forEach(key => {
    delete newAnswers[key];
  });
  return newAnswers;
};

const checkCondition = (state, index, animateForward) => {
  const debug = false;

  // Fallback if step not exist just go to first one
  // Usually happen when there is condition as last step
  // so script is jumping to next step after
  // condition fail which actually not exist
  if (!state.steps[index]) {
    return {
      ...state,
      currentStep: 0,
    };
  }

  const controls = _.get(state, `steps[${index}].controls`);
  const controlsConditionLogic = controls && controlsHasConditionLogic(controls);
  const stepConditionLogic = _.get(state, `steps[${index}].conditional_logic`);
  const showCurrentStep = !stepConditionLogic || isConditionGroupTrue(stepConditionLogic, state.answers);

  if (stepConditionLogic || controlsConditionLogic) {
    const controlsKeyVisibility = {};
    const keysToReset: Array<string> = [];
    debug && console.log('--------------------------------------------------');

    if (controlsConditionLogic) {
      _.forEach(controls, el => {
        const isVisible = isConditionGroupTrue(el.conditional_logic, state.answers);
        controlsKeyVisibility[el.key] = isVisible;
        if (isVisible === false) {
          keysToReset.push(el.key);
        }
      });
    }

    if (showCurrentStep) {
      debug && console.log('showCurrentStep', controlsKeyVisibility);

      // reset controlls keys

      return {
        ...state,
        answers: cleanupAnswers(state.answers, keysToReset),
        conditionalKeysVisibility: {
          ...state.conditionalKeysVisibility,
          ...controlsKeyVisibility,
        },
        currentStep: animateForward ? state.currentStep + 1 : state.currentStep - 1,
      };
    }

    debug && console.log('condition (' + index + ') not Passed call function again');

    if (controls) {
      _.forEach(controls, control => {
        keysToReset.push(control.key);
      });
    }

    const newState = {
      ...state,
      answers: cleanupAnswers(state.answers, keysToReset),
      conditionalKeysVisibility: {
        ...state.conditionalKeysVisibility,
        ...controlsKeyVisibility,
      },
      currentStep: animateForward ? state.currentStep + 1 : state.currentStep - 1,
    };

    if (showCurrentStep) {
      return newState;
    }

    switch (animateForward) {
      case false:
        return checkCondition(newState, index - 1, false);
      default:
        return checkCondition(newState, index + 1, true);
    }
  }
  else {
    debug && console.log('--------------------------------------------------');
    debug && console.log('conditional_logic NOT found in index: ' + index);
    debug && console.log('UPDATED STATE', state);

    return {
      ...state,
      currentStep: animateForward ? state.currentStep + 1 : state.currentStep - 1,
    };
  }
};

export default checkCondition;
