import React from 'react';
import * as _ from 'lodash';
import { BasePage, PageReducer } from '../../../interfaces';
import { Loader, NothingToDisplay, ErrorDisplay, Typography } from './../../index';
import ConsumptionChart from './ConsumptionChart';
import { useTranslation } from 'react-i18next';
import store from 'store2';
import { scrollToTop, getVariable } from '../../../helpers';

export interface PageConsumptionProps extends Omit<BasePage, 'page'> {
  getMyUse: Function;
  page: PageReducer;
  clearFilters: Function;
}

const PageConsumption: React.FC<PageConsumptionProps> = props => {
  const { getMyUse, appWidth } = props;
  const [t] = useTranslation('common');
  const swsmInternal = getVariable('mode', 'swsm', false);

  React.useEffect(() => {
    getMyUse();

    if (swsmInternal) {
      scrollToTop();
    }

    store('swsmLocation', {
      postcode_store: null,
    });
  }, [getMyUse, swsmInternal]);

  const { loading, error, done } = props.page.status;
  const { data } = props.page;
  const isDesktop = appWidth > 700;

  if (error)
    return (
      <React.Fragment>
        <ErrorDisplay />
      </React.Fragment>
    );

  if (loading && !done)
    return (
      <div style={{ position: 'relative', height: '100px' }}>
        <Loader />
      </div>
    );

  if (done && !data)
    return (
      <React.Fragment>
        <ErrorDisplay description={t('portal.my_use.page.nodata.error')} />
      </React.Fragment>
    );

  if (done && _.isEmpty(data))
    return (
      <React.Fragment>
        <NothingToDisplay />
      </React.Fragment>
    );

  const text_page_title = t('portal.my_use.page_title');
  const text_page_description = t('portal.my_use.page_description');

  return (
    <React.Fragment>
      <Typography
        size="1"
        weight={700}
        text={text_page_title}
        style={{ marginBottom: '16px', fontSize: '40px', color: '#0b2641' }}
      />
      <Typography
        size="p"
        style={{ fontSize: '18px', fontWeight: 700, maxWidth: '760px' }}
        text={text_page_description}
      />
      {_.map(data, (el, index) => {
        return <ConsumptionChart key={index} data={el} isDesktop={isDesktop} />;
      })}
    </React.Fragment>
  );
};

export default PageConsumption;
