import React from 'react';
import { Button, Input, Select, Icon } from '@savewatersavemoney/swsm-ui';
import { Col, Row } from 'react-styled-flexboxgrid';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Styled from './ContactForm.style';
import { useTranslation } from 'react-i18next';
import { Typography } from '../index';
import Reaptcha from 'reaptcha';

const ContactForm: React.FC<any> = props => {
  const [t] = useTranslation('common');
  const skipRecaptcha = process.env.REACT_APP_SKIP_RECAPTCHA === 'true';
  const CommunicationSchema = Yup.object().shape({
    waterCompany: Yup.string().required(t('portal.general_form_errors.required')),
    name: Yup.string().required(t('portal.general_form_errors.required')),
    phone: Yup.string()
      .required(t('portal.general_form_errors.required'))
      .matches(
        /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/,
        t('portal.general_form_errors.contact_not_valid'),
      ),
    orderNumber: Yup.string(),
    email: Yup.string()
      .email(t('portal.general_form_errors.email_required'))
      .required(t('portal.general_form_errors.required')),
    query: Yup.string().required(t('portal.general_form_errors.required')),
    recaptchaResponse: Yup.string().required('reCAPTCHA is required'),
  });

  const selectWaterCo = () =>
    [
      { text: 'Affinity Water', value: 'affinity' },
      { text: 'Anglian Water', value: 'anglian' },
      { text: 'Bournemouth Water', value: 'bournemouth' },
      { text: 'Bristol Water', value: 'bristol' },
      { text: 'Cambridge Water', value: 'cambridge' },
      { text: 'Dwr Cymru Welsh Water', value: 'dwrcymru' },
      { text: 'Essex and Suffolk Water', value: 'essex' },
      { text: 'Hafren Dyfrdwy', value: 'hdwater' },
      { text: 'Jersey Water', value: 'jerseywater' },
      { text: 'Northern Ireland Water ', value: 'northern' },
      { text: 'Northumbrian Water', value: 'nothumbrian' },
      { text: 'Portsmouth Water', value: 'portsmouth' },
      { text: 'Scottish Water', value: 'scottish' },
      { text: 'SES Water', value: 'seswater' },
      { text: 'South East Water', value: 'southeast' },
      { text: 'Southern Water', value: 'southern' },
      { text: 'South Staffs Water', value: 'southstaffs' },
      { text: 'South West Water', value: 'southwestwater' },
      { text: 'Severn Trent Water', value: 'stwater' },
      { text: 'Thames Water', value: 'thames' },
      { text: 'United Utilities', value: 'unitedutilities' },
      { text: 'Wessex Water', value: 'wessex' },
      { text: 'Yorkshire Water', value: 'yorkshire' },
    ].map(option => {
      return { value: option.value, label: option.text };
    });
  const ticketReference = t('portal.customer_service.ticket_reference');

  const sumbitThankyou = () => {
    return (
      <Styled.Wrap style={{ margin: 'auto' }}>
        <Icon icon="checkRounded" size={100} style={{ margin: 'auto', width: '100%' }} />
        <Typography style={{ paddingTop: 30, textAlign: 'center' }} size="p" text="Thank you." />
        <b>
          <Typography
            size="p"
            style={{ textAlign: 'center' }}
            text={`${ticketReference}: ${props.contactForm.data.zendId}`}
          />
        </b>
      </Styled.Wrap>
    );
  };

  const text_translation = {
    customer_service: {
      ask_me_button_text: t('portal.customer_service.ask_me_button_text'),
      ask_me_form_subject: t('portal.customer_service.ask_me_subject'),
      ask_me_form_title: t('portal.customer_service.ask_me_form_title'),
      required: t('portal.customer_service.required'),
      form_elements: {
        contact_number: t('portal.customer_service.form_elements.contact_number'),
        email: t('portal.customer_service.form_elements.email'),
        name: t('portal.customer_service.form_elements.name'),
        order_number: t('portal.customer_service.form_elements.order_number'),
        query: t('portal.customer_service.form_elements.query'),
        query_about: t('portal.customer_service.form_elements.query_about'),
        query_freetext: t('portal.customer_service.form_elements.query_freetext'),
        water_company: t('portal.customer_service.form_elements.water_company'),
      },
      live_query: t('portal.customer_service.live_query'),
      waterbutt_form_query: t('portal.customer_service.waterbutt_form_query'),
      waterbutt_form_title: t('portal.customer_service.waterbutt_form_title'),
    },
    options: {
      missing_product: t('portal.customer_service.missing_product'),
      fault_in_product: t('portal.customer_service.fault_in_product'),
      missing_part: t('portal.customer_service.missing_part'),
      postcode_entry: t('portal.customer_service.postcode_entry'),
      other: t('portal.customer_service.other'),
    },
  };
  const selectOptions = () =>
    [
      { text: 'Missing product', value: 'Missing_product' },
      { text: 'Fault with product', value: 'Fault_with_product' },
      { text: 'Missing part', value: 'Missing_part' },
      { text: 'Postcode query', value: 'Postcode_query' },
      { text: 'Other', value: 'Other' },
    ].map(option => {
      return { value: option.value, label: option.text };
    });
  return (
    <React.Fragment>
      <Row>
        {props.contactForm.status.done ? (
          sumbitThankyou()
        ) : (
          <Formik
            initialValues={{
              waterCompany: '',
              name: '',
              phone: '',
              orderNumber: '',
              email: '',
              query: '',
              query2: 'Contact_us',
              freeText: '',
              subject: 'Contact us form',
              recaptchaResponse: skipRecaptcha ? 'test123' : '',
            }}
            validationSchema={CommunicationSchema}
            onSubmit={(values, { setSubmitting }) => {
              props.sendContactUs(
                values,
                () => setSubmitting(false),
                () => setSubmitting(false),
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <Styled.Wrap>
                  <Col xs={12} sm={12} md={12}>
                    <Select
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.waterCompany.toString()}
                      aria-label={text_translation.customer_service.form_elements.water_company}
                      name="waterCompany"
                      id="waterCompany"
                      data-test={text_translation.customer_service.form_elements.water_company}
                      placeholder={`${text_translation.customer_service.form_elements.water_company}*`}
                      error={errors.waterCompany && touched.waterCompany ? errors.waterCompany : undefined}
                      options={selectWaterCo()}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={12}>
                    <Input
                      label={`${text_translation.customer_service.form_elements.name}*`}
                      type="text"
                      id="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      error={errors.name && touched.name ? errors.name : undefined}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={12}>
                    <Input
                      label={`${text_translation.customer_service.form_elements.contact_number}*`}
                      type="text"
                      id="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      error={errors.phone && touched.phone ? errors.phone : undefined}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={12}>
                    <Input
                      label={text_translation.customer_service.form_elements.order_number}
                      type="text"
                      id="orderNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.orderNumber}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={12}>
                    <Input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label={`${text_translation.customer_service.form_elements.email}*`}
                      type="email"
                      id="email"
                      value={values.email}
                      error={errors.email && touched.email ? errors.email : undefined}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={12}>
                    <Select
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.query.toString()}
                      aria-label={text_translation.customer_service.form_elements.query_about}
                      name="query"
                      data-test={text_translation.customer_service.form_elements.query_about}
                      placeholder={`${text_translation.customer_service.form_elements.query_about}*`}
                      options={selectOptions()}
                      error={errors.query && touched.query ? errors.query : undefined}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={12} style={{ display: 'none' }}>
                    <Input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label={text_translation.customer_service.form_elements.query_about}
                      type="text"
                      id="text"
                      value={values.query2}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={12}>
                    <Styled.Textarea
                      style={{ resize: 'none', width: '100%' }}
                      rows={5}
                      cols={49}
                      id="freeText"
                      value={values.freeText}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={text_translation.customer_service.form_elements.query_freetext}
                    ></Styled.Textarea>
                  </Col>

                  {skipRecaptcha ? null : (
                    <Col xs={12} sm={12} md={12} style={{ margin: '16px 0px' }}>
                      <Reaptcha
                        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA || ''}
                        onVerify={response => setFieldValue('recaptchaResponse', response)}
                        onExpire={() => setFieldValue('recaptchaResponse', '')}
                      />
                      {errors.recaptchaResponse && touched.recaptchaResponse && (
                        <Styled.ErrorInline>{errors.recaptchaResponse}</Styled.ErrorInline>
                      )}
                    </Col>
                  )}
                  <Col xs={12} sm={12} md={12}>
                    <i>* -{text_translation.customer_service.required}</i>
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '25px',
                    }}
                  >
                    <Button disabled={isSubmitting} type="submit">
                      {t('portal.forgot_password.submit_button')}
                    </Button>
                  </Col>
                </Styled.Wrap>
              </form>
            )}
          </Formik>
        )}
      </Row>
    </React.Fragment>
  );
};

export default ContactForm;
