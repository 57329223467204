import styled, { css } from 'styled-components';
import { colors } from '../../../assets/variables';

const InputWrapper = styled.div`
  max-width: 400px;
  position: relative;
  display: block;
  margin-top: 30px;
  z-index: 10;
  button {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 15px 20px;
  }
`;

const Input = styled.input`
  display: block;
  font-size: 1.25rem;
  color: #4a4a4a;
  padding: 1em;
  border: 0 solid transparent;
  width: 100%;
  background-color: #fff;
  font-weight: 400;
  border: 1px solid ${colors.primary};
  line-height: inherit;
  min-height: 65px;
  letter-spacing: 1px;
  border-radius: 5px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  ${props => {
    if (props.bg) {
      return css`
        background-color: #d7f1ff;
      `;
    }
  }}
  #main {
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    overflow: visible;
    @media screen and (max-width: 900px) {
      overflow: hidden;
    }
    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  ${props => {
    if (props.isDesktop) {
      return desktopStyles;
    }
  }}
`;

const Wrapper = styled.div`
  padding: 32px 0;
`;

const Cta = styled.div`
  margin: 20px auto 0 auto;
  width: 100%;
`;
const List = styled.ul`
  font-size: 16px;
  margin-bottom: 0px;
  li {
    padding-bottom: 5px;
  }
`;

const Card = styled.div`
  position: relative;
  box-shadow: 3px 3px 6px 3px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  padding: 16px;
  margin-bottom: 16px;
  align-items: center;
  max-height: 168px;
`;

const Title = styled.h3`
  color: #0b2641;
  font-size: 16px;
  margin: 0;
  font-weight: bolder;
  text-align: center;
  margin-bottom: 16px;
`;

const Error = styled.div`
  background-color: red;
  color: white;
  padding: 10px;
`;

const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  width: 500px;
`;

const IconStyles = css`
  border-bottom: 1px solid #d7f1ff;
  border-right: 1px solid #d7f1ff;
  border-top: none;
  position: relative;
  border-radius: 0;
  padding: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  &:last-child {
    border-bottom: none;
  }
  &:nth-last-child(2) {
    border-bottom: none;
  }
  &:nth-last-child(3) {
    border-bottom: none;
  }
  &:nth-last-child(4) {
    border-bottom: none;
  }

  &:nth-child(2n) {
    border-left: 1px solid #d7f1ff;
    border-right: 1px solid #d7f1ff;
  }
  &:nth-child(4n) {
    border-right: none;
  }

  @media screen and (max-width: 700px) {
    &:last-child {
      border-bottom: none;
    }
    &:nth-last-child(2) {
      border-top: 1px solid #d7f1ff;
    }
    &:last-child {
      border-top: 1px solid #d7f1ff;
    }
    &:nth-child(2n) {
      border-right: none;
    }
  }
`;

const IconGrid = styled.div`
  display: grid;
  margin: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 0px;
  padding: 10px;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
    width: 100%;
    padding: 0;
    margin-bottom: 40px;
  }
`;
const IconItem = styled.div`
  ${props => {
    if (!props.integration) {
      return IconStyles;
    }
  }}
  border-top: none;
  position: relative;
  border-radius: 0;
  padding: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;
const BoxTitle = styled.h3`
  min-height: 50px;
  color: #0b2641;
  @media screen and (max-width: 420px) {
    font-size: 14px;
    min-height: 38px;
  }
  text-align: center;
`;

const HeroContent = styled.div`
  width: 100%;
  @media screen and (max-width: 769px) and (min-width: 415px) {
    width: 60%;
  }
`;
const HeroTitle = styled.h1`
  font-size: 26px;
  line-height: 1.3em !important;
  margin-bottom: 30px;
  font-weight: 900;
  color: ${colors.primary};
  margin-bottom: 10px;
  @media screen and (max-width: 500px) and (min-width: 380px) {
    padding-right: 160px;
  }
`;
const HeroDescription = styled.h2`
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5em !important;
  margin-bottom: 30px;
  color: ${colors.primary};
`;

const SubSectionItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0px;
  &.border-item {
    border-bottom: 1px solid black;
  }
`;

const SubSectionValue = styled.div`
  font-size: 32px;
  color: ${colors.action};
  font-weight: 700;
  text-align: center;
  padding: 8px 0px;
`;
const desktopStyles = css`
  ${Cta} {
    width: 300px;
    margin: 20px auto 0 auto;
  }
  ${SubSectionItem} {
    &.border-item {
      border-right: 1px solid black;
      border-bottom: 0px;
    }
  }
  ${SubSectionValue} {
    text-align: center;
  }
  ${HeroTitle} {
    font-size: 40px;
  }
  ${HeroDescription} {
    line-height: 1.5em;
    font-size: 24px;
  }
  ${HeroContent} {
    margin-top: 40px;
    width: 'auto';
  }
`;

export default {
  BoxTitle,
  IconItem,
  IconWrap,
  IconGrid,
  Error,
  Main,
  Wrapper,
  Cta,
  List,
  Card,
  Title,
  InputWrapper,
  Input,
  HeroContent,
  HeroTitle,
  HeroDescription,
  SubSectionItem,
  SubSectionValue,
};
