import styled from 'styled-components';
// import { colors } from '../../../assets/variables';

export const CheckboxControl = styled.div`
  flex: 0 0 100%;
  .controls {
    text-align: left;
  }
`;

export default { CheckboxControl };
