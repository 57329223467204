import { PageReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

const initialState: PageReducer = {
  status: {
    loading: false,
    error: false,
    done: false,
  },
  data: null,
};

const pageConsumptionReducer = createReducer(initialState, {
  GET_MY_USE_PENDING: state => {
    state.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  GET_MY_USE_FULFILLED: (state, action) => {
    state.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.data = action.payload.data;
  },

  GET_MY_USE_REJECTED: (state, action) => {
    state.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.data = action.payload.data;
  },
});

export default pageConsumptionReducer;
