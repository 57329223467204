import { isFinite as _isFinite } from 'lodash';

export const numberFormatter = (number, fractionDigits = 0) => {
  const thousandSeperatorLang = ',';
  const fractionSeperatorLang = '.';
  if ((number !== 0 && !number) || !_isFinite(number))
    return number;
  const frDigits = _isFinite(fractionDigits) ? Math.min(Math.max(fractionDigits, 0), 7) : 0;
  const num = number.toFixed(frDigits).toString();

  const parts = num.split('.');
  const digits = parts[0].split('').reverse();
  let sign = '';
  if (num < 0) {
    sign = digits.pop();
  }
  const final: any = [];
  let pos = 0;

  while (digits.length > 1) {
    final.push(digits.shift());
    pos++;
    if (pos % 3 === 0) {
      final.push(thousandSeperatorLang);
    }
  }
  final.push(digits.shift());
  return `${sign}${final.reverse().join('')}${frDigits > 0 ? fractionSeperatorLang : ''}${
    frDigits > 0 && parts[1] ? parts[1] : ''
  }`;
};

export const prettifyIfNumber = (stringOrNumber: string | number, digits = 0): string | number => {
  const storage = localStorage.getItem('i18nextLng');
  const hindiNumber = stringOrNumber.toLocaleString('en-IN');
  if (typeof stringOrNumber === 'number') {
    if (storage === 'hi-IN') {
      return hindiNumber;
    }
    return numberFormatter(stringOrNumber, digits);
  }
  return stringOrNumber;
};

export const capitalizeFirstLetter = (string: any): any => {
  if (typeof string === 'string' || string instanceof String) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string;
};

export const pluralize = (count, noun, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`;

export const renderParameter = parameter => {
  const url_string = window.location.href;
  const myUrl = new URL(url_string.replace('#/', ''));
  const param = myUrl.searchParams.get(parameter);

  return param && param !== '' ? param : undefined;
};
