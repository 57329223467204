import React from 'react';
import Styled from './Loader.style';
import { Icon } from '@savewatersavemoney/swsm-ui';
interface LoaderProps {
  type?: string;
}

const Loader: React.FC<LoaderProps> = props => {
  const { type } = props;
  const loaderType = type => {
    switch (type) {
      case 'leaf':
        return (
          <Styled.LeafLoad>
            <div>
              <Icon icon="leaf" size={20} colorPrimary="#86B049" />
            </div>
            <div>
              <Icon icon="leaf" size={20} colorPrimary="#86B049" />
            </div>
            <div>
              <Icon icon="leaf" size={20} colorPrimary="#86B049" />
            </div>
          </Styled.LeafLoad>
        );
      default:
        return (
          <Styled.Loader>
            <div />
            <div />
            <div />
            <div />
          </Styled.Loader>
        );
    }
  };
  return loaderType(type);
};

export default Loader;
