import { connect } from 'react-redux';
import { actionsSavingEngine } from '../../actions/qaIndex';
import QADebugBar from './QADebugBar';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = state => {
  return {
    savingengineReducer: state.savingengineReducer,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    goTo: index => dispatch(actionsSavingEngine.goTo(index)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QADebugBar);
