import { connect } from 'react-redux';
import Qa from './Qa';
import { actionsModal, actionsSavingEngine } from '../../../actions/qaIndex';
import { getLocationQA } from '../../../actions/index';

// interface StateFromProps {}
// TODO: ask for that
// interface DispatchFromProps {
//   modalShow: (modalType: string, modalProps: Object) => void;
// }

const mapStateToProps = state => {
  return {
    app: state.appReducer,
    swsmLocation: state.locationReducer,
    steps: state.savingengineReducer.steps,
    currentStep: state.savingengineReducer.currentStep,
    questionsDone: state.savingengineReducer.fetched,
  };
};

const mapDispatchToProps = dispatch => ({
  getQuestions: (waterClient: string, utmId) => dispatch(actionsSavingEngine.getQuestions(waterClient)),
  getLocation: (postcode, setSubmitting) => dispatch(getLocationQA(postcode, setSubmitting)),
  modalShow: (modalType: string, modalProps: Object) => dispatch(actionsModal.modalShow(modalType, modalProps)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Qa);
