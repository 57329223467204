import { connect } from 'react-redux';
import Summary from './Summary';
import { actionsSavingEngine } from '../../../actions/qaIndex';
import { getAddresses } from '../../../actions/rewardProgram';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state, ownProps) => {
  return {
    swsmLocation: state.locationReducer,
    data: state.savingengineReducer.steps[ownProps.currentStep],
    answers: state.savingengineReducer.answers,
    answersStatus: state.savingengineReducer.summary.answersStatus,
    user: state.userReducer,
    page: state.pageFreeProductsReducer,
    qaProgress: {
      tracking_id: state.savingengineReducer.tracking_id,
      postcode: state.locationReducer.data.postcode,
      water_client_id: state.locationReducer.data.water_client_id,
      question_id: state.savingengineReducer.steps[ownProps.currentStep].id,
    },
  };
};
// const accessToken = _.get(userReducer, 'data.accessToken', false);

const mapDispatchToProps = dispatch => {
  return {
    postUserData: (data, answers, redirectUrl) =>
      dispatch(actionsSavingEngine.postUserData(data, answers, redirectUrl)),
    modalShow: (modalType, modalProps) => dispatch(actionsSavingEngine.modalShow(modalType, modalProps)),
    postProgress: (data, type) => dispatch(actionsSavingEngine.postProgress(data, type)),
    getAddresses: postcode => dispatch(getAddresses(postcode)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
