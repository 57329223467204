import React from 'react';
import Styled from './CheckboxControl.style';
import { CheckboxControlProps } from '../../../interfaces';
import { Checkbox } from '@savewatersavemoney/swsm-ui';

const CheckboxControl: React.FC<CheckboxControlProps> = props => {
  const { answerKey, value, desc, text, required, validateRequiredKeys, updateAnswerKey = () => void 0 } = props;

  const handleUpdate = event => {
    updateAnswerKey(answerKey, event.target.checked);
    if (required) {
      validateRequiredKeys();
    }
  };

  return (
    <Styled.CheckboxControl>
      <div className="controls">
        <Checkbox
          style={{ marginBottom: '16px' }}
          boxSize={35}
          type="checkbox"
          name={`GWF-${answerKey}`}
          id={`GWF-${answerKey}`}
          checked={!!value}
          onChange={event => {
            handleUpdate(event);
          }}
        >
          {text ? (
            <div
              style={{ fontSize: '16px', fontWeight: 'bold', display: 'flex', height: '39px', alignItems: 'center' }}
            >
              {text}
            </div>
          ) : (
            ''
          )}
          {desc === null ? (
            <div
              style={{
                fontSize: text ? '14px' : '18px',
                paddingTop: text ? 0 : '7px',
              }}
            >
              {desc}
            </div>
          ) : null}
        </Checkbox>
      </div>
    </Styled.CheckboxControl>
  );
};

export default CheckboxControl;
