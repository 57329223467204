import styled from 'styled-components';
import { colors } from '../../assets/variables';

const Wrap = styled.div`
  width: 100%;
  line-height: 1.4em;
  border-radius: 10px;
  border: 4px solid ${colors.tertiary};
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  flex-direction: column;
  height: auto;
  padding: 20px 20px 40px;
  margin-bottom: 16px;
  h3 {
    color: ${colors.primary};
  }
`;

const WrapSmall = styled.div`
  width: 100%;
  line-height: 1.4em;
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  flex-direction: column;
  height: auto;
  padding: 20px;
  margin-bottom: 16px;
`;

export default { Wrap, WrapSmall };
