import { ChallengesReducer } from '../interfaces';
import * as _ from 'lodash';
import { createReducer } from '@reduxjs/toolkit';

const initialState: ChallengesReducer = {
  status: {
    loading: false,
    error: false,
    done: false,
  },
  bonusChallengesStatus: {
    loading: false,
    error: false,
    done: false,
  },
  featuredChallenge: {},
  atLoadingState: [],
  bonusChallenges: {},
  active: {},
  userChallenges: {},
  completedPledges: {},
  smartMeterSignup: undefined,
  leakFixSignup: undefined,
  noEasyWinsAvailable: false,
  challengeList: {},
};

const challengesReducer = createReducer(initialState, {
  GET_CHALLENGES_PENDING: state => {
    state.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  UPDATE_CHALLENGE: (state, action) => {
    const target = state.challengeList[action.challenge.action.payload.data.activeChallenge.id];

    if (!target) {
      return;
    }
    target.pledgeClickCount = action.challenge.action.payload.data.activeChallenge.pledgeClickCount;
    target.pledgeLastClicked = action.challenge.action.payload.data.activeChallenge.pledgeLastClicked;
    target.completed = action.challenge.action.payload.data.activeChallenge.completed;

    const status =
      action.challenge.action.payload.data.status === 'INPROGRESS'
        ? 'STARTED'
        : action.challenge.action.payload.data.status;

    target.challenge.status = status;

    delete state.challengeList[action.challenge.action.payload.data.activeChallenge.id];

    const newState = Object.assign({}, state.challengeList, { [target.id]: target });

    state.challengeList = { ...newState };
  },

  GET_FEATURED_CHALLENGE_DATA: (state, action) => {
    const target = action.challenge;
    state.featuredChallenge[target.id]['challenge'] = { ...target };
  },

  GET_CHALLENGES_FULFILLED: (state, action) => {
    const activeChallenges = {};
    const userChallenges = {};
    const completedPledges = {};

    _.forEach(_.get(action, 'payload.data.challenges', []), challenge => {
      if (challenge.status === 'STARTED') {
        activeChallenges[challenge.id] = {
          id: challenge.id,
          completed: false,
          pledgeClickCount: challenge.pledge_clicks,
          pledgeLastClicked: challenge.pledge_last_click,
          challenge,
          category: challenge.category,
          type: challenge.type,
          internalType: challenge.internalType,
          energySaving: challenge.energy_saving,
        };
      }

      if (challenge.status === 'AVAILABLE') {
        userChallenges[challenge.id] = {
          id: challenge.id,
          completed: false,
          pledgeClickCount: challenge.pledge_clicks,
          pledgeLastClicked: challenge.pledge_last_click,
          challenge,
          buttonText: challenge.buttonText,
          category: challenge.category,
          type: challenge.type,
          internalType: challenge.internalType,
          energySaving: challenge.energy_saving,
        };
      }

      if (challenge.status === 'COMPLETED') {
        completedPledges[challenge.id] = {
          id: challenge.id,
          completed: true,
          pledgeClickCount: challenge.pledge_clicks,
          pledgeLastClicked: challenge.pledge_last_click,
          challenge,
          category: challenge.category,
          type: challenge.type,
          internalType: challenge.internalType,
          energySaving: challenge.energy_saving,
        };
      }
    });

    state.challengeList = { ...activeChallenges, ...userChallenges, ...completedPledges };

    state.status = {
      loading: false,
      error: false,
      done: true,
    };
  },

  GET_BONUS_CHALLENGES_FULFILLED: (state, action) => {
    const bonusChallenges = {};
    const featuredChallenge = {};

    _.forEach(_.get(action, 'payload.data.challenges', []), challenge => {
      if (challenge.type === 'bonus') {
        // TODO - create interface for featured challenge
        featuredChallenge[challenge.id] = {
          id: challenge.id,
          completed: challenge.pledge_clicks === challenge.pledge_required_clicks,
          pledgeClickCount: challenge.pledge_clicks,
          pledgeLastClicked: challenge.pledge_last_click,
          challenge,
          category: challenge.category,
          type: challenge.type,
          internalType: challenge.internalType,
          tag: challenge.tag,
        };
      }
      else {
        bonusChallenges[challenge.id] = {
          title: challenge.title,
          description: challenge.description,
          icon: challenge.icon,
          image: challenge.imageUrl,
          buttonText: challenge.buttonText,
          buttonPath: challenge.buttonPath,
          internalType: challenge.internalType,
          tag: challenge.tag,
        };
      }
    });

    state.bonusChallenges = bonusChallenges;
    state.featuredChallenge = featuredChallenge;

    state.bonusChallengesStatus = {
      loading: false,
      error: false,
      done: true,
    };
  },

  GET_BONUS_CHALLENGES_PENDING: (state, action) => {
    state.bonusChallengesStatus = {
      loading: true,
      error: false,
      done: false,
    };
  },

  GET_CHALLENGES_REJECTED: state => {
    state.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.challengeList = {};
  },

  START_CHALLENGE_PENDING: (state, action) => {
    const { id } = action.meta;
    state.atLoadingState.push(id);
  },

  START_CHALLENGE_FULFILLED: (state, action) => {
    // const { tag } = action.meta;
    // if (state.userChallenges.items) {
    //   state.userChallenges.items[tag].completed = true;
    // }
    // state.atLoadingState = _.filter(state.atLoadingState, el => {
    //   return el !== tag;
    // });
  },

  START_CHALLENGE_REJECTED: (state, action) => {
    const { tag } = action.meta;

    state.atLoadingState = _.filter(state.atLoadingState, el => {
      return el !== tag;
    });
  },

  SMART_METER_SIGNUP: state => {
    state.smartMeterSignup = true;
  },

  LEAK_FIX_SIGNUP: state => {
    state.leakFixSignup = true;
  },
});

export default challengesReducer;
