import { ModalShow, ModalActionTypes } from '../interfaces';

export const modalShow = (modalType: string, modalProps: Object): ModalShow => {
  return {
    type: ModalActionTypes.MODAL_SHOW,
    modalType,
    modalProps,
  };
};

export const modalHide = () => {
  return {
    type: ModalActionTypes.MODAL_HIDE,
  };
};
