import { LeaderboardPageReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

const initialState: LeaderboardPageReducer = {
  status: {
    loading: false,
    error: false,
    done: false,
  },
  donateStatus: {
    loading: false,
    error: false,
    done: false,
  },
  data: null,
};

const pageLeaderboardReducer = createReducer(initialState, {
  GET_PAGE_LEADERBOARD_PENDING: state => {
    state.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  GET_PAGE_LEADERBOARD_FULFILLED: (state, action) => {
    state.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.data = action.payload.data;
  },

  GET_PAGE_LEADERBOARD_REJECTED: (state, action) => {
    state.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.data = action.payload.data;
  },
  DONATE_POINTS_PENDING: state => {
    state.donateStatus = {
      loading: true,
      error: false,
      done: false,
    };
  },

  DONATE_POINTS_FULFILLED: (state, action) => {
    state.donateStatus = {
      loading: false,
      error: false,
      done: true,
    };
    if (state.data) {
      state.data.charityBox = action.payload.data.charityBox;
    }
  },

  DONATE_POINTS_REJECTED: (state, action) => {
    state.donateStatus = {
      loading: false,
      error: true,
      done: false,
    };
  },
});

export default pageLeaderboardReducer;
