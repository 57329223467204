import React from 'react';
import { connect } from 'react-redux';

// These are regular React components we will write soon
import ModalMultipleHelp from './ModalMultipleHelp';
import ModalReset from './ModalReset';

const MODAL_COMPONENTS = {
  MODAL_RESET: ModalReset,
  MODAL_MULTIPLE_HELP: ModalMultipleHelp,
};

const ModalRoot = props => {
  const { modalType, modalProps } = props;

  if (!modalType) {
    return null;
  }
  const SpecificModal = MODAL_COMPONENTS[modalType];
  return <SpecificModal {...props} {...modalProps} />;
};

// CONNECT PROPS
const mapStateToProps = state => {
  return {
    modalType: state.modal.modalType,
    modalProps: state.modal.modalProps,
  };
};

export default connect(mapStateToProps)(ModalRoot);
