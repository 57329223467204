import React from 'react';
import { getControlKeys } from '../../../helpers';
import { reduce as _reduce, filter as _filter } from 'lodash';
import { CounterControlProps } from '../../../interfaces';
import Styled from './CounterControl.style';

const plusSvgPath = (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h30v30H0z" />
      <rect width="2" height="16" x="15" y="7" fill="#0B2641" rx=".5" transform="rotate(90 15.5 15)" />
      <rect width="2" height="16" x="15" y="7" fill="#0B2641" rx=".5" transform="rotate(-180 15.5 15)" />
    </g>
  </svg>
);

const minusSvgPath = (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h30v30H0z" />
      <rect width="2" height="16" x="15" y="7" fill="#0B2641" rx=".5" transform="rotate(90 15.5 15)" />
    </g>
  </svg>
);

const CounterControl: React.FC<CounterControlProps> = props => {
  const {
    answerKey,
    grouped,
    value,
    defaultValue,
    max,
    min,
    color_theme,
    updateAnswerKey = () => void 0,
    help_image_url,
    desc,
    answers,
    controls,
  } = props;

  let maxFromAnotherAnswer;
  let stepKeys;
  const maxByGroup: Array<string> = [];

  if (typeof max === 'string') {
    if (max in answers) {
      maxFromAnotherAnswer = answers[max];

      // Get all keys where group is same as this current one
      stepKeys = getControlKeys({
        controls: _filter(controls, { max }),
      });
      stepKeys &&
        stepKeys.forEach((key: string) => {
          if (key !== answerKey) {
            // skip if current
            maxByGroup.push(answers[key] || 0); // get current value from answers state
          }
        });
    }
    else {
      // key not exist use 0 as max
      maxFromAnotherAnswer = 0;
    }
  }
  else {
    maxFromAnotherAnswer = max;
  }

  // Sum array to have so (maxFromAnotherAnswer - groupTotal) is your max
  const groupTotal = _reduce(
    maxByGroup,
    (sum, n) => {
      return sum + parseInt(n, 10);
    },
    0,
  );

  // Check if value is defined
  const currentValue = value != null ? value : parseInt(defaultValue, 10);

  const minusDisabled = currentValue === min;
  const plusDisabled = currentValue === maxFromAnotherAnswer - groupTotal;

  const pmcNumberIncrease = e => {
    e && e.preventDefault(); //IOS double click fix
    if (currentValue < maxFromAnotherAnswer - groupTotal) {
      updateAnswerKey(answerKey, currentValue + 1);
    }
  };

  const pmcNumberDecrease = e => {
    e && e.preventDefault(); //IOS double click fix
    if (currentValue > min) {
      updateAnswerKey(answerKey, currentValue - 1);
    }
  };
  return (
    <Styled.Wraper grouped={grouped}>
      {help_image_url && <Styled.Image width={150} height={150} src={help_image_url} alt="" />}
      <Styled.Indicator id={answerKey}>
        <Styled.Button
          aria-label="Minus counter"
          type="button"
          disabled={minusDisabled}
          onClick={pmcNumberDecrease}
          data-test="pmcMinus"
        >
          <Styled.CenterCore>{minusSvgPath}</Styled.CenterCore>
        </Styled.Button>
        <Styled.IndicatorNumber color_theme={color_theme} className="pmcNumber">
          {currentValue}
        </Styled.IndicatorNumber>
        <Styled.Button
          aria-label="Plus counter"
          type="button"
          disabled={plusDisabled}
          onClick={pmcNumberIncrease}
          data-test="pmcPlus"
        >
          <Styled.CenterCore>{plusSvgPath}</Styled.CenterCore>
        </Styled.Button>
      </Styled.Indicator>

      {desc && <Styled.Desc>{desc}</Styled.Desc>}
    </Styled.Wraper>
  );
};

export default CounterControl;
