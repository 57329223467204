import styled from 'styled-components';
import { colors } from '../../../assets/variables';

const Wrap = styled.div`
  margin: 40px 0;
`;
const Title = styled.div`
  color: ${colors.primary};
  font-size: 18px;
  text-align: center;
  font-weight: 600;
`;

const Svg = styled.svg`
  margin: auto;
  display: block;
  padding: 20px 0;
`;

export default { Wrap, Title, Svg };
