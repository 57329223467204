import { UiReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

const initialState: UiReducer = {
  appWidth: 0,
};

const uiReducer = createReducer(initialState, {

  RESET: state => {
    state = initialState;
  },

  SET_COMPONENT_WIDTH: (state, action) => {
    state.appWidth = action.appWidth;
  },

});

export default uiReducer;
