import React from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Redirect } from 'react-router-dom';
import { BasePage } from '../../../interfaces';
import Styled from './GenericMarkdown.style';
import i18next from 'i18next';
import ContactForm from '../../ContactForm';
import Sidebar from '../../Sidebar';
import { scrollToTop, isLoggedIn } from '../../../helpers';

const { CancelToken } = axios;
let cancel: any = () => {};

interface SavingEngineProps extends BasePage {
  setCustomerService: Function;
}
class GenericMarkdown extends React.Component<SavingEngineProps, any> {
  constructor(props: SavingEngineProps) {
    super(props);
    this.state = {
      pageContent: null,
      error: undefined,
    };
  }

  componentDidMount() {
    axios
      .get(`/pages/${i18next.language}${this.props.location.pathname}.md`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(result => {
        this.setState({ pageContent: result.data });
      })
      .catch(err => {
        this.setState({ error: err });

        // redirect to homepage
      });

    scrollToTop();
  }

  componentWillUnmount() {
    cancel();
  }
  linkRenderer = (props: any) => {
    return (
      <a href={props.href} target="_blank" rel="noopener noreferrer">
        {props.children}
      </a>
    );
  };

  render() {
    const { error } = this.state;
    const { appWidth } = this.props;

    const isDesktop = appWidth > 700;
    if (error) {
      return <Redirect to="/404" />;
    }

    const logged = Boolean(isLoggedIn());

    const renderForm = () => {
      if (this.props.location.pathname === '/contact-us') {
        return <ContactForm />;
      }
    };

    return (
      <Row style={{ marginBottom: '20px', marginTop: logged ? '0px' : '128px' }}>
        <Col xs={12} sm={isDesktop ? 9 : 12}>
          <Styled.Wrapper isDesktop={isDesktop}>
            <ReactMarkdown components={{ a: this.linkRenderer }}>{this.state.pageContent}</ReactMarkdown>
            {renderForm()}
          </Styled.Wrapper>
        </Col>
        {isDesktop ? (
          <Col xs={12} sm={3}>
            <Sidebar location={this.props.location} />
          </Col>
        ) : null}
      </Row>
    );
  }
}

export default GenericMarkdown;
