import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Col } from 'react-styled-flexboxgrid';
import { Error } from './ResetPassword.style';
import Styled from '../../../assets/SharedStyles.style';
import { Button, Input } from '@savewatersavemoney/swsm-ui';
import { useTranslation } from 'react-i18next';

interface ResetPasswordProps {
  resetPasswordUpdate: Function;
  resetToken: any;
  resetUpdate: any;
}

const ResetPassword: React.FC<ResetPasswordProps> = props => {
  const { resetPasswordUpdate, resetToken, resetUpdate } = props;
  const [hidden, setHidden] = React.useState(false);
  const showVisibility = () => setHidden(!hidden);
  const [t] = useTranslation('common');

  const ResetSchema = Yup.object().shape({
    password: Yup.string()
      .min(2, t('portal.general_form_errors.too_short'))
      .max(50, t('portal.general_form_errors.too_long'))
      .required(t('portal.general_form_errors.required')),
    password2: Yup.string()
      .oneOf([Yup.ref('password'), null], t('portal.general_form_errors.password_match'))
      .required(t('portal.general_form_errors.required')),
  });
  return (
    <Formik
      initialValues={{ password: '', password2: '' }}
      validationSchema={ResetSchema}
      onSubmit={(values, { setSubmitting }) => {
        resetPasswordUpdate(values.password, resetToken);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <Styled.FormGroup>
            <Col xs={12}>
              <Styled.Label>
                <Input
                  label={t('portal.forgot_password.new_password_label')}
                  type={hidden ? 'text' : 'password'}
                  name="password"
                  id="password"
                  aria-label={t('portal.forgot_password.new_password_label')}
                  placeholder="Type your password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  icon={hidden ? 'visible' : 'hidden'}
                  iconClick={showVisibility}
                  error={errors.password && touched.password ? errors.password : undefined}
                />
              </Styled.Label>
            </Col>
          </Styled.FormGroup>
          <Styled.FormGroup>
            <Col xs={12}>
              <Input
                label={t('portal.forgot_password.retype_password_label')}
                type={hidden ? 'text' : 'password'}
                name="password2"
                id="password2"
                aria-label={t('portal.forgot_password.retype_password_label')}
                placeholder="Retype your password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password2}
                icon={hidden ? 'visible' : 'hidden'}
                iconClick={showVisibility}
                error={errors.password2 && touched.password2 ? errors.password2 : undefined}
              />
            </Col>
          </Styled.FormGroup>
          {resetUpdate.status.error ? (
            <Styled.FormGroup>
              <Col xs={12}>
                <Error>{resetUpdate.status.error}</Error>
              </Col>
            </Styled.FormGroup>
          ) : null}
          <Col xs={12}>
            <Button
              style={{ width: '100%', textAlign: 'center' }}
              disabled={isSubmitting || resetUpdate.status.loading}
              type="submit"
            >
              {t('portal.forgot_password.submit_button')}
            </Button>
          </Col>
        </form>
      )}
    </Formik>
  );
};

export default ResetPassword;
